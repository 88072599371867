.product-format {
  font-size: 12px;
  letter-spacing: -0.05em;
  line-height: 20px;
  color: $text-contrast;
  text-align: left;
  width: 100%;
  padding: 0 20px;
  font-weight: 500;
  display: flex;

  &__tag {
      flex: 0.4;
  }

  &__info {
    flex: 1;
    display: list-item;
    margin-left: 1.5em;
    line-height: 18px;
  }

  span {
    background: $gray-6;
    padding: 4px 12px;
    font-size: 13px;
    border-radius: 20px;
    margin-right: 5px;
    font-weight: bold;
  }

  &__schedule {
    display: none;
  }
}
  