@import '../../global/styles/variables';

// CHECKOUT ----------------------------------------------------------------------------------------------
.checkout-wrapper {
  position: absolute;
  height: 104.5%;
  width: 100%;
  border-width: 0px 0px 1px 1px;
  padding-top: 2rem;
  top: 0;
  z-index: 10;

  &--marketplace {
    padding-top: 2.5rem;
  }

  &--scrolled {
    position: fixed;
    padding-top: 1.5rem !important;

    .side-checkout {
      background-color: var(--white);
      width: auto;
    }
  }

  &--show-sidebar-scrolled {
    .sales-argument {
      min-height: 1.625rem;
      transition: all 0.25s $ease;

      &__list {
        &__item {
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 180%;
          color: var(--gray-800);
          padding-bottom: 0.25rem;
        }

        &--sticky {
          .sales-argument__list__item {
            list-style: none;

            &:before {
              content: '';
              position: absolute;
              left: 5px;
              margin-top: 4px;
              width: 15px;
              height: 15px;
              background-repeat: no-repeat;
            }
          }
        }
      }

      &__rating {
        display: flex;
        align-items: center;
      }

      &--warranty {
        &:before {
          background-image: url('/product/static/images/icons/warranty.png');
        }
      }

      &--certification {
        &:before {
          background-image: url('/product/static/images/icons/checked.png');
        }
      }

      &--community {
        &:before {
          background-image: url('/product/static/images/icons/chat_blue.svg');
        }
      }

      &--sparkle {
        &:before {
          background-image: url('/product/static/images/icons/mobile.png');
        }
      }

      &--classes {
        &:before {
          background-image: url('/product/static/images/icons/classes.png');
          margin-top: 6px !important;
        }
      }

      &--review {
        &:before {
          background-image: url('/product/static/images/icons/review.png');
        }
      }

      &--students {
        &:before {
          background-image: url('/product/static/images/icons/students.png');
        }
      }

      &--warranty-redesign {
        &:before {
          background-image: url('/product/static/images/icons/warranty-redesign.png');
        }
      }

      &--certification-redesign {
        &:before {
          background-image: url('/product/static/images/icons/certification-redesign.png');
        }
      }

      &--mobile {
        &:before {
          background-image: url(/product/static/images/icons/product_mobile.png);
          left: 3px !important;
        }
      }

      &--hidden {
        min-height: 0 !important;
        height: 0;
        opacity: 0;
      }
    }
  }

  .box {
    border-radius: 0 0 5px 5px;
  }

  .checkout-digital-product {
    font-size: 10px;
    color: var(--gray-500);
    text-align: left;

    &.digital-product-schedule {
      margin-top: 16px;
    }
  }

  .text-center {
    text-align: center;
  }

  @media (min-width: $desktop-m) {
    padding-top: 120px;

    &--marketplace-redesign {
      padding-top: 2.5rem;

      &.checkout-wrapper--scrolled {
        padding-top: 0 !important;
      }
    }
  }
}

.checkout-common {
  .checkOut--access-side {
    white-space: nowrap;
    font-size: 14px;
  }
}

.sidebar {
  &__access {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    text-decoration: auto;
    font-size: 14px;
    line-height: 20px;
    color: var(--black);
    padding-bottom: 1.5rem;
    letter-spacing: 0.02em;

    @media (max-width: $mobile-l) {
      padding-bottom: 0;
    }

    &--highlight {
      font-weight: bold;
      margin-left: 0.25rem;
      z-index: 2;
    }

    &-icon {
      display: flex;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: #f0f3f7;
      margin-left: -0.5rem;
      z-index: 1;
    }

    &-link {
      display: flex;
      align-items: center;
      font-size: 0.875rem !important;
      color: var(--blue-darker) !important;
      background-color: var(--blue-lightest) !important;
      border-radius: 4px !important;
      gap: 4px;
      margin-left: 0.5rem;
    }
  }
}

.checkOut {
  position: relative;
  background-color: $white;
  transition: height, opacity 0.5s;
  width: 340px;

  &.side-checkout {
    box-sizing: border-box;
    transform-origin: left top;
    transition: all 0.7s ease-in-out;
    transform: translateY(-102%);
    opacity: 0;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: inherit;

    .price--value--amount {
      span {
        font-size: 18px;
      }
    }

    .sidebar {
      &__options {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        color: var(--gray-800);
      }

      &__title {
        color: var(--blue);
      }

      &__avatar {
        padding-bottom: 1.5rem;

        .image-avatar {
          top: 0 !important;
          width: 40px !important;
          height: 40px !important;
          transform: translateX(0) !important;
        }

        .author__avatar {
          width: 40px;
          height: 40px;
        }
      }
    }

    .sidebar__options,
    .checkout-digital-product,
    .sidebar__avatar {
      border-top: 1px solid var(--gray-200);
      padding-top: 1.5rem;
    }

    &.show-checkout {
      opacity: 1;
      transform: translateY(0);
    }

    &.show-about {
      .checkout-about-container {
        opacity: 1;
        max-height: 400px;
        transition-delay: 0.3s;
        z-index: 4;
      }
      .checkout-container {
        opacity: 0;
        max-height: 0px;
        transition-delay: 0.3s;
        z-index: 3;
      }
    }

    .checkout-about-container {
      opacity: 0;
      max-height: 0px;
      transition-delay: 0.3s;
      z-index: 3;

      .socialMedia {
        padding: 20px;
        padding-right: 0px;
      }

      .cardBubble {
        border: 0px;
        &:last-child {
          margin-left: 0px;
        }
      }
    }

    .checkout-container {
      max-height: 800px;
      transition-delay: 0.3s;
      z-index: 4;
    }

    .checkout-container,
    .checkout-about-container {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      max-width: 297px;
      top: -15px;
      left: 68px;
      transition: top 0.25s ease-out;
      transition-delay: 0.25s;
      display: flex;
      flex-direction: column;

      @media (max-width: 1279px) {
        left: 54px;
      }

      &__buy {
        min-height: 125px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .servico-online-container {
      .checkout-container__buy {
        min-height: 0;
      }
    }

    .checkout-container--sticky {
      position: fixed;
      top: calc(1rem + 50px);
      z-index: 101;
      opacity: 1;
      margin-left: 0;
      width: 340px;
      max-width: 340px;
      left: 40px;
      transition: top 0.25s ease-in-out;
      -webkit-animation: fadeIn 0.5s ease-in-out;
      animation: fadeIn 0.5s ease-in-out;
      border-radius: 6px;
      box-shadow: 0 -2px 7px 0 rgb(22 22 22 / 1%),
        0 2px 10px 0 rgb(80 80 80 / 10%);
      padding: 2rem;
      background-color: var(--white);

      .sales-argument__list__item:before {
        left: auto !important;
        margin-left: -1.2rem;
      }
    }

    .link-button {
      margin-bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      outline: inherit;
      margin-top: 0.5rem;

      &.link-button-service.schedule {
        margin-bottom: 0;
      }

      div {
        display: flex;
        align-items: center;
        width: fit-content;
      }
    }

    .price {
      color: var(--gray-600);
      font-weight: normal;
      transition: all 0.5s;
    }

    &.show-title {
      .checkout-container {
        padding-top: 0px;
      }
      .title {
        height: auto;
        max-height: inherit;
        transition: opacity 0.5s $ease;
      }

      .price {
        margin-top: 15px;
      }

      .event__online-box {
        .price {
          margin-top: 0;
        }
      }
    }

    .copy-text {
      font-size: 12px;
      color: var(--gray-800);

      &.price-copy-schedule {
        text-align: left;
        margin-bottom: 8px;
      }
    }

    .product-format {
      margin-bottom: 4px;
      padding: 0px;
    }

    &.show-title {
      .title {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .online-event-container {
      text-align: left;
      justify-content: flex-start;

      .online-event {
        &.title {
          text-align: left;
          span {
            margin-left: 0;
          }
        }
        .eventSchedule__container {
          margin-left: 0;
          margin-bottom: 24px;

          .eventSchedule__hour {
            justify-content: flex-start;
          }
        }
      }

      .price {
        text-align: left;
        margin-bottom: 8px;

        .price--value--amount {
          font-size: 30px;
          color: $text-contrast;
        }

        .price--value {
          line-height: 30px;
        }
      }

      .copy-text {
        margin-top: 0;
        margin-bottom: 8px;
        text-align: left;
        color: var(--gray-900);
      }

      .coCards {
        justify-content: flex-start;

        .credcards {
          width: 25px !important;
          height: 21px;
          margin: 0 2.5px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .product-format {
        text-align: left;
      }

      .event__online-box {
        border-radius: 6px;
        border: 1px solid var(--gray-400);
        padding: 12px;
        margin-bottom: 24px;
      }

      .event__online-title {
        margin-bottom: 8px;
        h6 {
          margin: 0;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: bold;
          color: $text-contrast;
        }
      }

      .link-button {
        margin-bottom: 0;
      }
    }

    .sales-argument {
      margin: 0 0 1rem 0.5rem;
      text-align: left;

      &--4,
      &--5 {
        min-height: 162px;
        display: flex;
        flex-direction: column;
      }

      &--range {
        min-height: 167px;
      }

      &--6 {
        min-height: 185px;
      }

      &--2 {
        min-height: 80px;
      }

      &--3 {
        min-height: 100px;
      }

      &__list {
        margin: 0;
        padding-left: 16px;
        margin-top: 0.75rem;
        height: auto;
        list-style-image: url('/product/static/images/icons/check.svg');

        ::marker {
          margin-bottom: 12px;
        }

        &__item {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  &.bottom-checkout {
    position: fixed;
    overflow: hidden;

    min-width: 320px;
    max-height: 500px;
    min-height: 70px;
    width: 100%;
    height: 96px;

    bottom: 0;
    left: 50%;

    padding: 12px 16px;

    z-index: 9999 !important;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
    transform: translateX(-50%);
    border-top: 1px solid $gray-6;

    .bottom-checkout-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $mobile-max) {
          width: 180px;
          height: auto;
        }
      }

      @media (max-width: $mobile-m) {
        .link-arrow-text {
          font-size: 12px;
        }

        .title-area {
          &--closed {
            padding-top: 0.5rem;
          }
        }
      }

      @media (max-width: $mobile-l) {
        .title-area {
          &--open {
            font-size: 12px !important;
            width: 127px;
          }
        }
      }

      @media (max-width: $mobile) {
        .title {
          span {
            font-size: 12px !important;
          }
        }

        .bottom-checkout-content {
          &-button {
            padding: 10px 10px 5px 3px;
            flex: auto;

            .link-button {
              padding: 12px;
            }

            &.checkout-closed {
              .icon {
                width: 32px;
                height: 32px;
              }
            }
          }

          &-title {
            width: 155px;

            .title-area {
              font-size: 10px;
              width: 165px;

              &--closed {
                padding-top: 12px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .rating {
      text-align: left;
    }

    .price {
      overflow: hidden;
      &__previous {
        margin-bottom: 0px;
        &__label {
          color: #2f2f2f;
          font-size: 14px;
        }
        &__discount {
          background: transparent;
          margin-left: 1px;
          &__label {
            font-weight: 900;
            font-size: 16px;
            border-radius: 50px;
            padding: 0px 8px;

            @media (min-width: $mobile) {
              background: none;
              color: #ef4a24;
            }

            @media (min-width: $desktop) {
              font-size: 15px;
              background: var(--hotmart-primary-dark);
            }
          }
        }
      }
    }

    .copy-text {
      &.price-copy-abtest {
        font-size: 12px;
        font-weight: bold;
        color: $green;
      }
      &.price-copy-event {
        white-space: nowrap;
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-tertiary;
      }
    }

    .title-event {
      padding-left: 10px;

      .price-area {
        .price {
          padding-left: 0;
        }
      }
      .title-area-ticket {
        font-size: 9px;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
    }

    .button-area {
      display: flex;
      padding: 10px 0 10px 10px;
      flex: 0 0 auto;
      .link-button {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;

        div {
          display: flex;
          align-items: center;
        }

        &.disabled {
          padding: 10px 20px;
        }
      }
      @media (max-width: 767px) {
      }
      @media (max-width: 414px) {
        flex: 1 0 auto;
      }

      &.checkout-closed {
        justify-content: flex-end;
        display: flex;

        @media (max-width: $mobile) {
          font-size: 12px;

          .arrow-icon {
            min-width: 12px !important;
          }
        }
      }
    }

    .title-area {
      padding: 0 20px;
      max-height: 60px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1 auto;
      padding-left: 0;

      .title {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          font-size: 20px;
          line-height: 20px;
          font-weight: bold;
        }
      }
    }

    .price-area {
      flex: 1 0 auto;
      text-align: right;

      .price {
        text-align: right;
        &--value {
          font-size: 25px;
          .price--value--amount {
            span {
              font-size: 14px;
            }
          }
        }

        &--description {
          font-size: 14px;
          line-height: 16px;
          margin-top: 2px;
        }
      }
    }

    @media (max-width: 767px) {
      .price-area {
        .price {
          text-align: left;

          &--value {
            font-size: 20px;
          }
        }
      }

      .copy-text {
        text-align: left;
      }

      &.has-price {
        .avatar-area {
          display: none;

          &.opened {
            display: block;
          }
        }

        .title-area {
          display: none;
        }
      }
    }

    &--session-package {
      padding-top: 0.25rem;

      .button-area {
        padding-top: 0.25rem;
      }

      .sessionPackageInfo {
        padding-top: 0.1rem;
      }
    }
  }
}

@media (max-height: $tablet) {
  .checkOut {
    &.side-checkout {
      .checkout-container {
        &--sticky {
          top: calc(1rem + 0px);
          height: auto;
        }
      }
    }
  }
}
