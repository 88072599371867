.arrow {
  margin-left: 0.125rem !important;

  &.arrow-down {
    transform: rotate(180deg);
  }

  &.arrow-up {
    transform: rotate(0);
  }
}

.button {
  &__read-more {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
}