#webinar {
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;

  .webinar__advantages {
    display: flex;
    flex-direction: column;


    h5 {
      font-size: 12px;
      font-weight: bold;
      color: $gray-7;
      text-transform: uppercase;
      margin: 0 0 16px 0;
    }
  }

  .webinar__description {
    font-size: 24px;
    color: $text-contrast;
    word-break: break-word;
    line-height: normal;
  }

  .webinar__banner {

    img {
      width: 100%;
      height: 100%;
    }
  }

  .webinar__presentation {
    display: flex;
    align-items: center;
    margin-bottom: 21px;

    p {
      font-size: 14px;
    }

    svg {
      position: relative;
      margin-right: 12px;
    }
  }

  @media (min-width: $mobile) {
    flex-direction: column;

    .webinar__banner.mobile{
      width: 283px;
    }

    .desktop {
      display: none;
    }

    .webinar__advantages {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 16px;

      svg {
        width: 30px;
        height: 24px;
      }
    }
  }

  @media (min-width: $mobile-m) {
    .webinar__banner.mobile{
      width: 343px;
    }
  }

  @media (min-width: $desktop) {
    flex-direction: row;
    padding-top: 4rem;

    .webinar__advantages {
      width: 60%;
      margin-top: 0;
      margin-bottom: 0;

      p {
        //margin-bottom: 12px;/**/
      }
    }

    .webinar__description {
      width: 323px;
    }

    .webinar__banner {
      width: 375px;
      height: 237px;
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
}
