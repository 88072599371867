.error-page-not-found {
  background: url('https://hotmart.com/product/static/images/error/background-error-page.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
}

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .erro-page-image {

    @media (min-width: $mobile) {
      width: 100%;
    }
  }

  @media (min-width: $mobile) {
    padding: 0;
  }

  @media (min-width: $desktop) {
    padding: 0 65px;
    margin-top: 10rem;
  }
}

.error-page-info {
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile) {
    align-items: center;
  }

  @media (min-width: $desktop) {
    margin-left: 61px;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: flex-start;
  }
}

.error-page-image {
  width: 200px;
  height: 63px;
}

.error-page-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #464B52;
  line-height: 24px;
  margin-top: 16px;

  @media (min-width: $mobile) {
    width: 100%;
    padding: 8px 24px;
    text-align: center;
  }

  @media (min-width: $desktop) {
    width: 381px;
    padding: 0;
    text-align: left;
  }
}

.error-page-links {
  display: flex;


  @media (min-width: $mobile) {
    margin-top: 15px;
  }

  @media (min-width: $desktop) {
    margin-top: 32px;
  }
}

.error-page-link {
  background: #5981E3;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  width: 200px;
  padding: 10px;
  text-align: center;
}

.error-page-link a {
  color: #FFFFFF;
  text-decoration: none;
}

.error-page-plataform {
  margin-left: 16px;
}
