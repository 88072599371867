//Primary Text colors
$soft-red: hsl(14, 88%, 65%);

//Dividers
$light-grayish-blue: hsl(240, 5%, 91%);

.faq {
  width: 100%;

  &__title {
    margin: 0;
    padding-bottom: 25px;
  }

  &__question {
    width: 100%;
    border-bottom: 1px solid $light-grayish-blue;
  }

  &__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;

    padding-top: 18px;
    padding-bottom: 18px;

    &::after {
      content: url(/product/static/images/icons/icn-arrow-down.svg);
      margin-right: 17px;
      //transform: rotate(90deg);
    }

    &:hover {
      color: $soft-red;
    }
  }

  &__desc {
    margin-left: -40px;
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
    visibility: hidden;
  }
}

.faq__desc.show-description {
  height: 2rem;
  margin-bottom: 15px;
  visibility: visible;
}


/* closed */
.faq__question-button::after {
  transition: transform 300ms ease-out;
}

/* open */
.font-weight-bold::after {
  transform: rotate(180deg);
}


@media only screen and (max-width: 700px) {
  .faq {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__question-button {
      font-size: 13px;
      line-height: 24px;
      text-align: left;


      &::after {
        padding-right: 0px;
      }
    }
  }
}
