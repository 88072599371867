.tab-list-item {
  cursor: pointer;
  padding: 12px;
  display: inline-block;
  color: #9da8bd;
  transition: all 450ms ease;
  border-bottom: 3px solid transparent;
}

.tab-content {
  margin-top: 1rem;
  width: 100%;

  &__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;

    .share-component__lock {
      padding: 0.05rem 0.185rem 0 0 !important;
    }
  }

  .content {
    transition: opacity 450ms ease-in-out;
    display: flex;
    flex-direction: column;
    //margin-bottom: 24px;
    align-items: flex-start;

    .section_wrap {
      margin: 0;
      margin-bottom: 16px;

      span {
        font-size: 12px;
        color: var(--gray-500) !important;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;

        i {
          color: var(--purple) !important;
        }
      }

      @media(max-width: 720px) {
        margin-bottom: 16px;
        h3 {
          font-size: 12px;
          color: $color-tertiary;
        }
      }
    }

    .tab-item {
      margin-top: 1rem;
    }

    .cards-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .summary {
      width: 100%;
    }

    .accordion {
      .accordion__item {
        .accordion__heading {
          display: flex;
          justify-content: space-between;
        }

        .accordion__button {
          padding: 20px 16px;

          &[aria-expanded='true'] {

          }
        }

        .accordion__text {
          margin: 0 0 12px;
          line-height: 24px;

          &:last-child {
            margin: 0;
          }
        }

        @media(max-width: 720px) {
          .accordion__heading {
            flex-direction: column;
          }

          .accordion__button {
            padding: 20px 16px;
          }
        }
      }
    }

    #details {
      margin: 0;
      .section_detail {
        &_item {
          flex: 1 1 calc(50% - 6px);
          margin: 0;
          padding: 24px 6px 24px 0;
          border-bottom: 1px solid $gray-6;

          &.full {
            flex: 1 1 100%;

            &:last-child {
              border: none;
              padding-bottom: 0;
            }
          }

          &.small {
            flex: 1 1 33.3%;
          }
        }
        span {
          h3 {
            color: black;
          }
        }
        & > .section_detail_item:first-child, & > .section_detail_item:nth-child(2) {
          padding-top: 0;
        }
      }

      @media(max-width: 720px) {
        width: 100%;
      }
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
    height: 0;
    margin: 0;
    visibility: hidden;
  }

  .active {
    opacity: 1;
    pointer-events: auto;
    height: auto;
    visibility: visible;
  }

  .cover {
    width: 100%;
  }
}
