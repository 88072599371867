.author {
  scroll-margin-top: 100px;

  &__avatar-container {
    display: flex;
    margin-top: .5rem;

    @media(min-width: $tablet-mid) {
      margin-top: 1rem;
    }
  }

  .icon-light {
    height: .9rem !important;
  }

  &__avatar {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;

    @media(min-width: $tablet-mid) {
      width: 6rem;
      height: 6rem;
    }
  }

  &__name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
  }

  &__name {
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;
    color: #282C2F;
  }

  &__titles {
    font-weight: bold;
    font-size: .75rem;
    line-height: 150%;
    color: var(--gray-500);
  }

  &__social {
    display: flex;
    margin-top: 1rem;
    font-size: 1rem;

    a {
      text-decoration: none;
      color: var(--gray-500);

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    img {
      min-height: inherit !important;
      width: 1.125rem !important;
      height: 1.125rem !important;
    }

    @media(min-width: $tablet-mid) {
      margin-top: .625rem;
    }
  }

  &__biography {
    margin: 1.5rem 0 1rem;
    word-break: break-word;
  }
}
