@import './variables.scss';

$black-november-background: #f5cf67;

.bottom-checkout--bn,
.checkout-container--bn:not(.servico-online-container) {
  .content-loader--shine {
    background-image: linear-gradient(
      90deg,
      var(--gray-700) 0,
      var(--gray-900) 45%,
      var(--gray-800) 90%
    ) !important;
  }
}

.bottom-checkout--bn {
  background-color: var(--black) !important;
  box-shadow: 0 0 0 0 var(--black) !important;
  .sessionPackageInfo--mobile {
    display: none;
  }
}

.checkout-container--bn,
.bottom-checkout--bn .bottom-checkout-content {
  .price__previous__discount {
    &__label {
      background: $black-november-background !important;
      color: var(--brand-primary-dark) !important;
      font-size: 1.125rem;
      font-weight: 900;
      border-radius: 0 !important;
    }
  }

  .price__previous__label,
  .creator_info div,
  &.checkout-container--courses,
  &.checkout-container--courses .sidebar__access div,
  &.checkout-container--courses .sidebar_info > div,
  .tags__rating-global__total-reviews,
  .price--value span,
  .copy-text,
  .eventSchedule__container div,
  .event-label,
  .title-area-ticket,
  .title-area--open {
    color: var(--gray-200) !important;
  }
}

.checkout-container--bn,
.calendar-modal--bn .calendar-checkout-dates,
.calendar-modal--bn .calendar-checkout-hours__container {
  .checkout-container__buy {
    & > span {
      height: 60px !important;
    }
  }

  .calendar-checkout-back {
    margin-top: 0;
  }

  .react-datepicker h4,
  .calendar-checkout-hours__title,
  .calendar-checkout-back {
    color: var(--brand-primary-dark) !important;
  }

  .calendar-checkout-date__schedule--title {
    color: var(--gray-900);
  }

  .calendar-checkout-hour,
  .react-datepicker__header-button {
    &:hover {
      border-color: var(--brand-primary-dark) !important;
      color: var(--brand-primary-dark) !important;
    }

    &.selected {
      background-color: $black-november-background;
      color: var(--brand-primary-dark);
      border: 1px solid var(--brand-primary-dark);
    }
  }

  .DayPicker-Day {
    &--selected {
      background-color: var(--gray-200) !important;
      color: var(--gray-900) !important;

      &:hover {
        background: $black-november-background !important;
        color: var(--brand-primary-dark) !important;
        font-weight: normal;
      }
    }
  }
}

.checkout-container--bn.checkout-container--courses {
  background-color: var(--black) !important;
  background-image: url('/product/static/images/black-november/black-november-banner-topo.png');
  background-repeat: no-repeat;
  background-size: contain;

  ._text-gray-600,
  .sessionPackageInfo p {
    color: var(--gray-400) !important;
  }

  .sessionPackageInfo {
    background: var(--gray-900);

    span {
      width: 30px !important;
      height: 22px !important;
    }

    img {
      object-fit: cover;
    }

    strong {
      color: var(--gray-200) !important;
    }
  }

  .sidebar__access div,
  .creator_info > div:first-child {
    text-decoration: underline;
  }

  .price__previous {
    margin-bottom: 0;
  }

  ._border-top {
    border-top: 1px solid var(--gray-800) !important;
  }

  .sales-argument__icon {
    width: 1.25rem !important;

    span {
      height: 20px !important;
    }

    .image-icon {
      object-fit: none !important;
    }

    &--bn path {
      fill: var(--white)
    }
  }

  .sidebar__access {
    span {
      width: 1rem !important;
      height: 1rem !important;
    }

    .sidebar__access-redesign {
      transform: rotate(0);
    }
  }
}
