@import '../../global/styles/variables';

//Accordion
.accordion {
  border-radius: $borderRadius;
  overflow: hidden;
  outline: none;
  z-index: 2;
  position: relative;
  background: $white;
  width: 100%;

  &__item {
    margin: .5rem 0;
    font-weight: bold;
    padding-right: 1rem;
  }

  &__section {
    margin-right: 0.75rem;
    color: var(--blue);
  }

  &__title {
    font-weight: 400;

    &--extra {
      margin-right: 0.5rem;
    }
  }

  &__extra__module {
    font-size: 0.875rem;
  }

  &__extra__icon {
    padding-bottom: 0.25rem;
  }

  &__hours {
    font-size: 12px;
    letter-spacing: 0.02em;
    color: var(--gray-600);

    & > span {
      margin-bottom: -2px !important;
    }
  }

  &__icon {
    margin-left: 0.75rem;

    @media (max-width: $mobile-l) {
      display: flex;
      margin-left: 0;
    }
  }

  &__info {
    width: 100px;
    display: flex;
    align-items: center;

    & > span {
      margin-bottom: 0 !important;
      margin-right: 2px !important;
    }
  }
}

hot-collapse {
  .accordion__description {
    display: none;

    small {
      color: var(--gray-500);
    }
  }

  &[active] {
    .accordion__description {
      display: inherit;
    }
  }
}

.accordion__description {
  width: 100%;
  height: 100%;
  padding: 15px 20px 15px 16px;
  transition: all $timing5 $ease;
  white-space: pre-wrap;

  &.idented {
    padding: 15px 20px 15px 60px;
  }

  p {
    float: none;
  }
}

.accordion__text {
  margin: 0 0 1rem;
}

.free-module__link {
  color: #355CC0;
  line-height: 1.5rem;
}

.accordion__tag {
  @media (max-width: 750px) {
    width: fit-content;
    margin-top: 0.5rem;
  }
}
