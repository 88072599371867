@import "../../global/styles/variables";

// CARD -------------------------------------------------------------------------------------

.card {
  .card-content {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: $borderRadius;
    transition: all $ease $timing3;
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    background: $white;

    .img-cover {
      width: 100%;
      height: 220px;
      position: relative;
      margin: 0;
      overflow: hidden;
      transition: all $ease $timing3;
      text-align: left;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        transition: all $ease $timing3;
        position: absolute;
      }
    }
  }

  &:hover {
    z-index: 4;

    .card-content {
      border-radius: $borderRadius * 2;
      background: var(--color-theme);
      transform: translateY(-10px);
      box-shadow: 0 18px 13px -13px rgba($red, 0.8);

      .img-cover {
        img {
          opacity: 0.03;
          transform: scale(1.2);
        }

        .digest {
          opacity: 1;
          transition-delay: 50ms;
        }
      }

      h2,
      h3,
      .rating,
      .tag-category {
        color: $white;
      }

      .tag-category {
        background: var(--color-themeDark);

        text-shadow: 0 1px 1px rgba($hm, 0.2);
        box-shadow: 0 5px 5px -4px rgba($black, 0.3);
      }
    }
  }

  &.checkout-recover__card {
    .card-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding: 20px;

      p {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        padding: 12px 0;
        font-weight: 500;
        font-size: 16px;
        color: $gray-4;
        line-height: 22px;
        text-align: center;
        align-items: center;
        justify-content: left;
        letter-spacing: 0;
        transition: all $ease $timing3;
      }

      .logo-hm {
        width: 130px;
      }
    }

    &:hover {
      .card-content {
        border-radius: $borderRadius * 2;
        background: #fff;
        transform: translateY(0);
        box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
      }
    }
  }
}