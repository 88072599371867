#image-cover {
  overflow: hidden;

  .image-cover {
    width: 100%;
    max-height: 435px;
    border-radius: 6px;

    object-fit: contain;
    object-position: 50% 50%;
  }

  @media (min-width: $mobile) {
    height: 150px;
  }

  @media (min-width: $desktop) {
    height: 400px;
  }
}

#cover {
  @media(max-width: 1024px) {
    padding: 0;
  }
}
