.modal-backdrop {
  align-items: center;
  background-color: rgba(53, 58, 68, 0.9);
  display: none;
  height: 100%;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 999999;
  width: 100%;

  &--active {
    display: flex;
  }
}

.nowrap {
  white-space: nowrap;
}

.modal {
  background-color: white;
  display: flex;
  color: black;
  height: fit-content;
  margin: 0 1rem;
  width: 100%;
  position: relative;
  width: 975px;

  @media (min-width: $desktop) {
    margin: 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    @media (min-width: $desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__button {
    background: none !important;
    border: 0 !important;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    z-index: 100;
  }

  &__content {
    line-height: 1;
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: center;

    @media (min-width: $desktop) {
      padding: 2rem 2.5rem 0 2.5rem;
    }
  }

  &__title {
    margin-bottom: 1rem;

    .image-icon {
      display: none !important;

      @media (min-width: $desktop) {
        display: block !important;
      }
    }

    h2 {
      color: #ef4e23;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 1rem 0 0.25rem 0;
    }

    &__mobile {
      display: block;

      @media (min-width: $desktop) {
        display: none;
      }

      span {
        color: black;
      }
    }

    &__desktop {
      display: none;

      @media (min-width: $desktop) {
        display: block;
      }
    }

    p {
      color: #121212;
      display: none;
      font-size: 0.75rem;
      line-height: 1;

      @media (min-width: $desktop) {
        display: block;
      }
    }
  }

  &__iframe {
    height: 500px;
    margin: auto;
    max-width: 500px;
    overflow: hidden;
    width: 100%;

    @media (min-width: $tablet) {
      height: 500px;
    }

    @media (min-width: $desktop) {
      height: 450px;
    }

    iframe {
      border: 0;
      height: 650px;
      opacity: 1;
      transform: scale(0.85) translateY(-80px);
      width: 100%;

      @media (min-width: $tablet) {
        height: 580px;
        transform: scale(0.95) translateY(-80px);
      }
    }
  }

  &__sidebar {
    display: none;
    justify-content: center;
    margin: 0.5rem;
    overflow: hidden;
    position: relative;

    @media (min-width: $desktop) {
      display: flex;
    }
  }
}
