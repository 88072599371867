.review-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  height: 30rem;
  overflow-y: auto;

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    height: auto;

    .review-card-sizing {
      margin-right: 0;
      max-width: inherit;
    }
  }
}

.review-modal__container {
  justify-content: space-around;

  @media (max-width: $tablet) {
    justify-content: flex-start;
    overflow-y: scroll;
    height: 45rem;
    border-top: var(--component-border-width) solid var(--component-border-color);
    padding-top: 1rem;
  }
}
