@import '../../global/styles/variables';

$font-1: 'Nunito Sans', sans-serif;
$mobile-el: 797px;

.body-locked {
  overflow: hidden;
  position: relative;
  height: 100%;
  margin-left: initial !important;

  .content-center,
  .checkout-wrapper {
    overflow: hidden;
    height: 100%;
    opacity: 0.5;
  }

  .hm-mktplace-header--menu-open {
    background-color: var(--white) !important;
  }
}

header {
  height: 60px;
  width: 100%;
  position: fixed;
}

.hm-mktplace-header {
  $mktplace-header: &;
  background: #ffffff;
  max-width: 100%;

  * {
    box-sizing: border-box;
  }

  &--auto-hide {
    #{$mktplace-header}__container {
      position: fixed;
      width: 100%;
      top: 0px;
    }
  }

  &--hidden {
    #{$mktplace-header}__container {
      opacity: 1;
      transform: translateY(-100%);
    }
  }

  &--not-flying {
    #{$mktplace-header}__container {
      box-shadow: none;
    }
  }

  &--flying {
    #{$mktplace-header}__container {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--show {
    #{$mktplace-header}__container {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  * {
    font-family: $font-1;
    box-sizing: border-box;
  }

  &__search {
    padding: 0 5rem 0 0;

    &__container {
      z-index: 999995;
      position: fixed;
      transition: all $timing $ease;
      width: 100%;
      background: #ffffff;
      top: 0px;
      bottom: 0px;
      right: 0;
      left: 0;
      &--open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }

      &--closed {
        transform: translateY(-100%);
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__user {
    margin-right: 2rem;
  }

  &--menu-open {
    background-color: #e8e9e8 !important;
  }

  &__suggestion {
    display: none;
    align-items: center;
    justify-content: center;

    .hm-mktplace-header__suggestion {
      display: flex;
    }

    &__link {
      display: inherit;
      text-decoration: none;
      transition: all $timing $ease;
      color: #164d5f;

      .hm-tag {
        flex: 1 1 100%;
      }

      &:last-child {
        margin-right: 0px;
      }

      &:hover {
        color: $blue-1;
      }
    }
  }

  &__user {
    display: none;
    align-items: flex-end;

    svg {
      margin-left: 0.5rem;
    }
  }

  &__title {
    font-family: 'Nunito Sans';
    font-size: 16px;
    line-height: 24px;
    color: #191c1f;
  }

  &__organic {
    background-color: $blue-1;
    border: 1px solid $blue-1;
    border-radius: 0.25rem;
    color: $white;
    display: none;
    font-size: 0.625rem;
    margin: 0 0.5rem;
    order: -1;
    padding: 0.25rem 1rem;
    text-decoration: none;

    @media (min-width: $mobile-m) {
      margin: 0 0.75rem;
    }

    @media (min-width: $tablet) {
      background: transparent;
      border: 1px solid #bed1ff;
      color: #5981e3;
      font-size: 1rem;
      margin-left: 0.25rem;
      margin-right: 1.5rem;
      order: initial;
      padding: 0.5rem 1rem;
    }

    &--mobile {
      display: initial;

      @media (min-width: $desktop) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: $desktop) {
        display: initial;
      }
    }
  }

  &__container {
    transition: all $timing $ease;
    max-width: 100%;
    width: 100%;
    background: #ffffff;
    z-index: 160;
    padding: 0.2rem 0 0.2rem 1rem;

    &--center {
      width: 100%;
      max-width: 1920px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      position: relative;
      flex-direction: row;
    }

    .hm-burger-button {
      display: none;
    }

    @media (max-width: $tablet) {
      .hm-burger-button {
        border: none;
        background-color: rgba(0, 0, 0, 0);
        display: block;
        line-height: 10px;
        padding: 0.25rem;
        transition: all 500ms cubic-bezier(0.4, 0, 0.14, 1);
        cursor: pointer;
      }

      &.hm-mktplace-header--placeholder {
        align-items: flex-end !important;
      }
    }
  }

  &__logo {
    padding-right: 24px;
    flex-shrink: 0;
    padding: 0 1.5rem 0.8rem 0 !important;

    &__link {
      display: block;
    }

    svg {
      display: block;
      width: auto;
      min-width: 85px;
      max-width: 170px;
    }
  }

  &__menu {
    flex: 1 1 100%;
  }

  &__action {
    flex-shrink: 0;
    display: flex;
    max-width: 100%;
    align-items: center;

    &--logged-out {
      margin: 0.1rem 0.3rem 0;
    }

    .hm-search-close-button {
      padding: 0.5rem;
    }
  }

  &__options {
    list-style: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 1rem;
    min-width: 320px;
    top: 3rem;

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 70%;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-bottom-color: #ffffff;
      border-top: 0;
      margin-left: -19px;
      margin-top: -19px;
    }

    &--user {
      max-width: inherit;
      margin-top: 0;
    }

    &--menu {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 200px);
      }

      &:after {
        left: 10%;
      }
    }
  }

  &__session {
    flex-shrink: 0;
    margin-left: 48px;
    display: none;

    &__signin {
      text-decoration: none;
      color: #5981e3;
      border: 1px solid #bed1ff;
      border-radius: 4px;
      text-decoration: none;
      min-width: fit-content;
      padding: 0.5rem 1rem;
      transition: all 500ms cubic-bezier(0.4, 0, 0.14, 1);
      display: none;

      &--mobile {
        display: initial;
        padding: 8px 16px;
        border: 1px solid #5981e3;
        color: #355cc0;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
      }

      &:hover {
        color: #5981e3;
      }
    }

    &__signup {
      color: #5981e3;
      margin-left: 1.5rem;
      display: none;
      border-radius: 4px;
      text-decoration: none;
      min-width: fit-content;
      padding: 0.5rem 1rem;
      transition: all 500ms cubic-bezier(0.4, 0, 0.14, 1);

      &--mobile {
        display: initial;
        width: 100%;
      }

      &--active {
        background: #5981e3;
        color: #ffffff;
      }

      &:hover {
        color: #ffffff;
        background: #355cc0;
      }
    }
  }

  &-profile-mobile {
    display: none;
  }

  @media screen and (min-width: $tablet) {
    &__search {
      display: initial;
      max-width: 750px !important;
      width: inherit;
    }

    &__suggestion {
      display: flex;
    }

    &__logo {
      padding: 0 1.5rem 0.8rem 0 !important;
    }

    &__user {
      display: flex;
      overflow: hidden;
      text-align: center;
      line-height: normal;
      cursor: pointer;
      background: rgba(0, 0, 0, 0);
      border: none;
      align-items: center;
      text-align: center;
      cursor: pointer;
      background: rgba(0, 0, 0, 0);
      border: none;
      margin-right: 2rem;
      padding: 0px;
      font-size: 16px;
      line-height: 24px;
      color: #191c1f;
    }

    &--menu-open {
      .hm-mktplace-header__search,
      .hm-mktplace-header__title,
      .hm-mktplace-header__logo {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__options {
      align-items: center;

      &--user {
        right: 6rem;
        max-width: 320px;
        margin-top: 1rem;
      }
    }

    .show-carousel {
      text-decoration: auto;
    }

    &__container {
      padding: 0.75rem;

      &--center {
        justify-content: center;
      }
    }

    &__suggestion {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__session {
      &__signup {
        display: initial;
      }

      &__signin {
        display: initial;
      }
    }

    &__logo {
      svg {
        width: 200px;
        height: 32px;
        padding: inherit;
      }
    }
  }

  @media screen and (min-width: 1480px) {
    &__options {
      &--user {
        right: 12%;
      }
    }
  }

  @media screen and (min-width: $desktop-l) {
    &__options {
      &--user {
        right: 20%;
      }
    }
  }

  @media screen and (min-width: $desktop-m) {
    &__options {
      &--fullscreen {
        display: none;
      }
    }

    &__logo {
      padding-right: 0 !important;
    }
  }

  @media screen and (min-width: $mobile-el) {
    &__container {
      position: fixed;
      width: 100%;
      top: 0px;
    }

    &__search {
      &__container {
        position: fixed;
        transition: all $timing5 $ease;
        width: 100%;
        background: #ffffff;
        top: 86px;
        bottom: auto;
        right: auto;
        left: auto;

        &--open {
          z-index: 110;
          transform: translateY(0);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
        }

        &--closed {
          transform: translateY(-100%);
          z-index: -1;
        }
      }
    }

    &__session {
      display: block;
    }

    &-profile-mobile {
      display: block;
    }
  }
}

.hm-mktplace-search {
  &__autocomplete {
    position: relative;
    z-index: 100;
    background: #fff;
    border-radius: 6px;
    font-family: 'Nunito Sans', sans-serif;
    transition: all 0.5s;
    height: 40px;
    display: flex;
    align-items: center;

    &__data {
      border-radius: 0 0 0.5rem 0.5rem !important;
      box-shadow: none !important;
    }

    &__input {
      flex: 1 1 100%;
      &__field {
        padding: 8px 48px 10px 16px;
        font-weight: normal;
        font-family: 'Nunito Sans', sans-serif;
        background-color: #f7f9fa;
        font-size: 16px;
        width: 100%;
        height: 42px;
        border: 0px;
        color: #7a818a;
        box-sizing: border-box;
        z-index: 1;
        border-radius: 4px;
        outline: none;
        transition: all 0.5s;

        &::placeholder {
          color: inherit;
        }
      }
    }

    &__searchSubmit {
      border: 0;
      background-color: #f7f9fa;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      top: 0px;
      right: 1px;
      bottom: 0px;
      width: 48px;
      margin: 0px;
      transition: all cubic-bezier(0.4, 0, 0.14, 1) 500ms;
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      svg {
        position: absolute;
        z-index: 11;
      }
    }
  }
}

.hm-mktplace-menu__header__avatar--mobile {
  display: none;
}

.header-product {
  @media screen and (min-width: $desktop-m) {
    .hm-mktplace-header__logo {
      margin-left: -10rem;
    }
  }
}

.header-marketplace {
  .hm-mktplace-header__logo {
    padding: 0 !important;
    flex-shrink: 0;
    padding-right: 2rem !important;

    svg {
      max-width: inherit;
    }
  }

  @media screen and (min-width: $desktop-m) {
    .hm-mktplace-header__logo {
      svg {
        margin: 0 1.5rem 0 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: $tablet) {
  .hm-mktplace-menu__placeholder {
    display: flex;
    margin-bottom: -0.5rem;
    padding-right: 0.25rem;
  }

  .header-marketplace {

    .hm-mktplace-menu__header__avatar--mobile:not(
        .hm-mktplace-menu__placeholder
      ) {
      padding-bottom: 0.25rem;
    }
  }
}

@media (min-width: $tablet) {
  .header-marketplace {
    &--sticky {
      .hm-mktplace-header__container {
        transform: translateY(-200px) !important;
      }
    }

    &--fadeIn {
      .hm-mktplace-header__container {
        transition: all 800ms cubic-bezier(0.4, 0, 0.14, 1) !important;
        transform: translateY(0);
      }
    }
  }
}

.hm-search__icon-search {
  width: 16px;
  margin: 7px 16px 0;
}

.hm-mktplace-menu.hm-mktplace-menu--open {
  @media (max-width: $tablet) {
    z-index: 99999;
  }
}

.hm-mktplace-header__container {
  position: fixed;
  z-index: 99999 !important;

  @media (max-width: $tablet-mid) {
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%) !important;
  }
}
