@import '../../global/styles/variables.scss';
@import '../../global/styles/breakpoints.scss';

.carousel {
  position: relative;
  $self: &;

  &__content {
    display: flex;
    overflow: hidden;
    margin: 0 -16px;
    &--scrollable {
      overflow: auto;
    }

    // hiding scrollbar visually
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
  }

  .icon-chevron {
    width: 12px;
  }
  
  &__page {
    display: flex;
    max-width: 100%;
    &--last {
      margin-right: 15px;
    }
  }

  &__item {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0 16px;
  }

  &__carousel__final--spacing {
    display: block;
    flex: 0 0 1px;
    font-size: 1px;
    max-width: 1px;
  }

  &__bullets {
    position: relative;
    z-index: 16;

    &--left {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &__bullet {
      width: 18px;
      display: inline-block;
      border-top: 2px solid $grey-13;
      content: '';
      cursor: pointer;
      margin: 0 3px;
      &--active {
        border-top: 2px solid $grey-15;
      }
    }
  }
  &__arrow {
    background: $white;
    border-radius: 50%;
    border: solid 1px $red-3;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.18);
    height: 44px;
    padding: 10px;
    position: relative;
    transition: $timing-5 all $ease;
    width: 44px;
    opacity: 0;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);

    svg {
      color: $red-4;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: $timing-5 all $ease;
      width: 7px;
    }

    &:hover {
      background-color: $red-4;
      border: 1px solid $red-4;
      svg {
        color: $white;
      }
    }

    &--enabled {
      opacity: 1;
    }

    &__left,
    &__right {
      position: absolute;
      top: 50%;
      z-index: 10;
    }

    &__left {
      left: 0px;
    }
    &__right {
      right: 0px;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__content {
      margin: 0 -24px;
    }

    &__item {
      padding-left: 24px;
    }

    &__item {
      flex-shrink: 0;
      flex-grow: 0;
    }

    &__arrow {
      height: 64px;
      width: 64px;

      &__left {
        left: -58px;
      }

      &__right {
        right: -58px;
      }

      svg {
        width: 10px;
      }
    }
  }
}
