@import "../../global/styles/variables";

.calendar-checkout {
  width: 312px;
  margin: 0 auto;
  position: relative;
  transition: transform $timing-3 $ease;

  &-container {
    display: flex;
    flex-direction: column;
  }

  .icon-chevron {
    padding-top: 2px!important;
  }

  .icon-back {
    margin-right: 4px!important;
  }

  &-back {
    font-size: 14px;
    font-weight: bold;
    max-width: 120px;
    margin: 24px 0;
    display: flex;
    align-items: baseline;
    cursor: pointer;

    svg {
      margin-right: 12px;
      font-size: 16px;
    }

    @include hover-supported() {
      color: var(--blue);
    }

    @media (max-width: $mobile-content-max) {
      padding: 0 16px;
      max-width: 100%;
      margin: 16px 0;
    }
  }

  &-dates,
  &-hours {
    transition: transform $timing-5 $ease;
  }

  .show {
    opacity: 1;
    transform: translateX(0);
    z-index: 0;
    transition-delay: 200ms;
    transition: opacity $timing-5 $ease;
    pointer-events: auto;
    visibility: visible;
  }

  &.openSchedule {
    opacity: 1;
    transform: translateX(0);
    z-index: 0;
    transition-delay: 200ms;
    pointer-events: auto;
    visibility: visible;
  }

  &.hideSchedule {
    height: 0;
    opacity: 0;
    z-index: -1;
    transform: translateX(120%);
    margin: 0;
    padding: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .hide {
    height: 0;
    opacity: 0;
    z-index: -1;
    transform: translateX(120%);
    margin: 0;
    padding: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &-hours {
    margin-top: 24px;
    display: flex;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
    max-height: 330px;
    overflow-x: auto;

    &__title {
      color: var(--blue);
      font-size: 16px;
      margin-top: 0;
      text-align: left;

      @media (max-width: $mobile-content-max) {
        padding: 0 16px;
      }
    }

    @media (max-width: $mobile-content-max) {
      overflow-x: auto;
      padding: 0 16px;
      padding-bottom: 90px;
    }

    @media (max-width: $mobile) {
      padding-bottom: 120px;
    }

    @media (min-width: $desktop-min) and (min-height: $tablet) {
      max-height: 150px;
    }
  }

  &-hour {
    flex: 0 0 calc(50% - 8px);
    padding: 12px 10px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid $gray-9;
    margin-bottom: 8px;
    cursor: pointer;
    color: $gray-2;
    font-size: 12px;
    transition: all $timing-5 $ease;
    text-align: center;

    &:nth-child(odd) {
      margin-right: 8px;
    }

    @include hover-supported() {
      border-color: var(--blue);
      color: var(--blue);
      background-color: white;
    }

    &.selected {
      background-color: var(--blue);
      border-color: var(--blue);
      color: white;
    }

    &__schedule {
      margin-left: 5px;
      font-size: 12px;
      color: $gray-2;
      display: flex;

      &--check {
        margin-left: 8px;
        position: relative;
        height: 10px;
        width: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-check {
          fill: #24c662;
        }

        svg {
          width: 10px;
          height: 10px;

          @media (max-width: $mobile-content-max) {
            width: 12px;
            height: 12px;
          }
        }

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          background-color: rgba(40, 226, 119, 0.15);
          position: absolute;
          border-radius: 50%;

          @media (max-width: $mobile-content-max) {
            display: none;
          }
        }
      }

      @media (max-width: $mobile-content-max) {
        flex-direction: column;
        margin-right: 8px;
        margin-left: 0;
        font-size: 12px;
      }

      &--content {

        @media (max-width: $tablet-mid) {
          margin-left: 8px;
          display: flex;
          align-items: center;
        }

        @media (max-width: $mobile-content-max) {
          margin-left: 0;
        }

        strong {
          margin-left: 3px;
        }
      }

    }

    strong {
      margin-left: 3px;
    }

    @media (max-width: $mobile-content-max) {
      padding: 18px 12px;
    }

    @media (max-width: $mobile) {
      padding: 20px 6px;
    }
  }

  &-date {
    &__schedule {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-top: 12px;
      flex-wrap: wrap;

      &--title {
        font-size: 12px;
        color: var(--blue);
        margin-right: 8px;
        flex: 0 0 100%;
        text-align: left;
        font-weight: bold;
        margin-bottom: 5px;

        @media (max-width: $tablet-mid) {
          flex: auto;
          margin-bottom: 0;
        }

        @media (max-width: $mobile-content-max) {
          display: none;
        }
      }

      &--content {
        font-size: 12px;
        color: $gray-2;

        @media (max-width: $mobile-content-max) {
          font-size: 12px;
          display: flex;
        }

        .date {
          text-transform: capitalize;
          margin-right: 3px;
        }
      }

      @media (max-width: $tablet-mid) {
        padding-top: 0;
        strong {
          margin-left: 3px;
        }
      }
    }
    &__container {
      &.modal {
        padding: 12px 16px;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: $white;
        border-top: 1px solid rgba(115,115,115, 0.2);
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);

        &.show {
          opacity: 1;
          pointer-events: auto;
        }

        &.hide {
          opacity: 0;
          pointer-events: none;
        }

        .calendar-checkout-date__schedule {
          width: calc(100% - 189px);

          @media (max-width: $mobile) {
            width: calc(100% - 160px);
          }
        }

        .link-button-service,
        .link-button-service-checkout,
        .link-button-service-checkout-modal {
          width: 189px;
          padding: 12px;
          font-size: 16px;
          box-shadow: 0 2px 10px 0 #47f491;
          border: 1px solid #06bc54;
          margin-bottom: 0;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;

          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            background-color: $gray-9;
            color: $gray-4;
            opacity: 0.4;
            box-shadow: 0 2px 10px 0 transparent;
            border: 1px solid transparent;
          }

          @media (max-width: $mobile) {
            width: 160px;
            font-size: 14px;
          }
        }

      }
    }
  }

  .link-button-service,
  .link-button-service-checkout,
  .link-button-service-checkout-modal {
    margin-top: 17px;
    width: 100%;
    height: 54px;
    box-shadow: 0 2px 10px 0 #47f491;
    border: 1px solid #06bc54;
    margin-bottom: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      background-color: $gray-9;
      color: $gray-4;
      opacity: 0.4;
      box-shadow: 0 2px 10px 0 transparent;
      border: 1px solid transparent;

      svg {
        color: $gray-4;
        fill: $gray-4;
        display: none;
      }
    }
  }

  .loader {
    width: 100%;
    height: 350px;
    display: flex;
    position: absolute;
    background: none;
  }
}

.DayPicker {
  &-Caption {
    div {
      font-size: 16px!important;
      text-transform: capitalize;
      position: absolute;
      top: 2.7rem;
      width: 100%;
      left: 0;
      right: 0;
      margin-left: auto;
      color: #484e56;
      font-weight: bold!important;
      text-align: center;
    }
  }

  &-Month {
    border-spacing: 4px 4px!important;
    border-collapse: separate!important;
    margin-top: 0!important;
  }

  &-Day {
    &--selected {
      background-color: var(--blue)!important;
      color: $white!important;
      font-weight: normal;
      border-radius: 50%!important;
      outline: none!important;

      &:hover {
        background-color: var(--blue)!important;
        color: white!important;
      }
    }

    &--outside {
      visibility: hidden;
    }

    &--today:before {
      content: "";
      position: absolute;
      height: 4px;
      width: 4px;
      margin-left: 3px;
      margin-top: 1.2rem;
      border-radius: 50%;
      background-color: #cecece;
    }
  }

  &-WeekdaysRow {
    padding: 8px;
    margin: 0 4px;
    width: 35px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &-Weekday {
    color: #484e56!important;
    font-size: 9px!important;
  }
}

.react-datepicker {
  position: relative;
  display: flex;
  overflow-x: auto;

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    padding: 0;
    border: .45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;

    &--next {
      right: 0;
      border-left-color: #ccc;
    }

    &--previous {
      left: 0;
      border-right-color: #ccc;
    }
  }

  .react-datepicker__header {
    display: flex;
    flex-direction: column;

    &-info {
      display: flex;
      flex-direction: column;
      margin: 0 1rem 1rem 0;
      width: 100%;

      h4 {
        font-size: 16px;
        color: var(--blue);
        margin: 0 0 17px 0;
        text-align: left;
      }

      &__icon {
        margin-left: 8px;

        font-size: 13px;
        color: #6b7480;

        cursor: pointer;
      }

      @media (max-width: $mobile-content-max) {
        margin: 0;
        padding: 0 10px;

        h4 {
          font-size: 18px;
          padding-left: 6px;
        }
      }
    }

    &-picker {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-year {
      font-size: 16px;
      text-transform: capitalize;
      color: $gray-2;
      font-weight: bold;

      @media (max-width: $mobile-content-max) {
        font-size: 20px;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
    }

    &-button {
      border: 1px solid rgba(151,151,151,0.2);
      background-color: white;
      border-radius: 14px;
      font-size: 12px;
      color: $gray-2;
      font-weight: bold;
      z-index: 1;
      padding: 6px 10px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all $timing-5 $ease;

      &--previous {
        left: 0;
        svg {
          margin-right: 6px;
        }
      }

      &--next {
        right: 0;
        svg {
          margin-left: 6px;
        }
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      @include hover-supported() {
        border-color: var(--blue);
        color: var(--blue);
      }

      @media (max-width: $mobile-content-max) {
        padding: 7px 13px;
      }
    }

    &-month-name {
      font-size: 16px !important;
      color: #484e56;
      font-weight: 700 !important;
      text-align: center;
    }
  }

  .react-datepicker__current-month {
    display: flex;
    justify-content: center;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media (max-width: $mobile-content-max) {
      justify-content: space-around;
    }
  }

  .react-datepicker__day-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin: 0 4px;
    width: 35px;
    font-weight: normal;
    font-size: 9px;
    text-transform: uppercase;
    color: $gray-2;
  }

  .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-5;
    font-size: 16px;
    cursor: not-allowed;
    pointer-events: none;
    outline: none;
    padding: 8px;
    margin: 0 4px;
    width: 35px;
    height: 35px;

    &:hover {
      background-color: transparent;
    }

    &--highlighted {
      cursor: pointer;
      pointer-events: auto;
      outline: auto;
      color: white;
      &:hover {
        background-color: var(--blue);
        color: white;
      }
    }

    &--selected {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 5px;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: $gray-5;
      }
    }

    &--highlighted {
      background-color: rgba(255, 147, 119, 0.1);
      color: $color-primary;
      font-weight: normal;
      border-radius: 50%;
      outline: none;
    }


    @media (max-width: $mobile-content-max) {
      width: 48px;
      height: 48px;
      padding: 0;
      margin: 0 3px;
    }

    @media (max-width: $mobile) {
      height: 40px;
    }
  }

  @media (max-width: $mobile-content-max) {
    display: inherit;
  }

  @media (max-width: $mobile-m) {
    max-height: 400px;
    overflow-x: auto;
  }
}

.calendar-modal, .mobile-modal {
  position: fixed;
  background-color: $white;
  transition: transform $timing-8 $ease;
  pointer-events: none;
  height: 100%;
  width: 100%;

  &.opened {
    top: 0;
    left: 0;
    z-index: 99999;
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  &.hide {
    bottom: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(120%);
    visibility: hidden;
  }

  &__header {
    padding: 16px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-image {
      width: 110px;
      opacity: 0;
    }

    svg {
      width: 15px;
      height: 16px;
    }
  }

  &__container {
    padding: 0 16px 16px 16px;

    &.calendar {
      padding: 0;
    }
  }

  .back-button-avatar-wrapper {
    width: 48px;
    height: 48px;

    .back-button-avatar{
      width: 48px;
      height: 48px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    &-price {
      margin-left: 8px;
    }

    .copy-text.truncate.price-copy-schedule {
      font-size: 14px;
      white-space: normal;
    }
  }

  &__button {
    margin-top: 16px;
    font-size: 14px;
    line-height: 2;
    color: $gray-2;
    cursor: pointer;
    transition: color $timing-3 $ease;
    display: none;

    svg {
      margin-right: 12px;
    }

    @include hover-supported() {
      color: $color-primary;
    }
  }

  &__divisor {
    margin: 0;
    border-style: solid;
    border-color: rgb(151,151,151);
    opacity: 0.1;

    &.back {
      margin-bottom: 16px;
    }
  }

  .truncate {
    @media (max-width: $mobile) {
      max-width: 180px;
    }
  }
}

.calendar-modal__avatar {
  & > span {
    width: 2.8rem !important;
    border-radius: 1.5rem; 
    border: 1px solid var(--gray-200) !important;
  }
}

.link-button-service,
.link-button-service-checkout,
.link-button-service-checkout-modal {
  &.disabled {
    padding: 10px 20px;
    pointer-events: none;
    cursor: not-allowed;
    background-color: $gray-9;
    color: $gray-4;
    opacity: 0.4;
    box-shadow: none;
    border: none;

    svg {
      display: none;
    }
  }
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: var(--blue) !important;
  width: 36px !important;
  height: 34px !important;
}

.rdp-head_cell {
  color: #484e56 !important;
  font-size: 10px !important;
}

