.faq {
  .accordion__button {
    padding: 8px 16px;
  }

  .accordion__description {
    div {
      display: flex;
      align-items: baseline;
      white-space: normal;  
     
      span, a {
        margin-left: 5px;
        background: linear-gradient($grey-14, $grey-7) bottom no-repeat;
        background-size: 90% 2px;
        line-height: 20px;
        color: $grey-14;
        text-decoration: none;
        cursor: pointer;

        &:before, &:after {
          content: '”';
          position: initial;
          z-index: auto;
          opacity: 1;
          width: auto;
        }
      }

      @media (max-width: $tablet) {
        display: block;
        white-space: pre-line;

        span {
          float: initial;
          display: inline;
          margin: 0 5px;
        }
      }
    }

    a {
      color: var(--blue);
    }
  }
}
