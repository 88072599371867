@import '@hotmart/cosmos/dist/styles/utilities/cursor.css';
@import '@hotmart/cosmos/dist/styles/utilities/flex/flex.css';
@import '@hotmart/cosmos/dist/styles/utilities/text/text.css';
@import '@hotmart/cosmos/dist/styles/utilities/border/border.css';
@import '@hotmart/cosmos/dist/styles/utilities/sizing/sizing.css';
@import '@hotmart/cosmos/dist/styles/utilities/spacing/spacing.css';
@import '@hotmart/cosmos/dist/styles/utilities/overflow/overflow.css';
@import '@hotmart/cosmos/dist/styles/utilities/text/variations/grays.css';
@import '@hotmart/cosmos/dist/styles/utilities/border/variations/grays.css';
@import '@hotmart/cosmos/dist/styles/utilities/background/variations/grays.css';
@import '@hotmart/cosmos/dist/styles/utilities/display/display.css';
