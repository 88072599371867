@import '../../global/styles/variables';

.review-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-right: 1rem;

  &.clickable {
    cursor: pointer;
  }

  &.open {
    border-radius: 6px;
    cursor: auto;
  }

  .review-card-date {
    color: $gray-9;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    flex: 0 0 auto;
  }

  .review-card-minimized {
    margin: 15px 20px;
    .review-card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .rating {
        flex: 1 1 auto;
        margin: 0 0 2px;
      }
    }

    .review-card-text {
      font-weight: normal;
      font-size: 16px;
      color: $gray-2;
      flex: 1 1 auto;
      min-height: 100px;
    }

    .review-card-hotmarter {
      color: var(--gray-500);
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
    }
  }

  div {
    padding-top: inherit!important;
    
    .hotmarter-avatar {
      border-radius: 50% !important;
      border: 1px solid $gray-3 !important;
      height: auto!important;
    }
  }

  .review-card-maximized,
  .review-card-minimized {
    &.animating {
      animation-fill-mode: forwards;
      animation-name: reviewCardShowEffect;
      animation-duration: 1.5s;
      animation-timing-function: $ease;
    }


  }

  .review-card-maximized {
    margin: 20px;

    .review-card-header {
      text-align: right;
    }

    .review-card-body {
      .review-card-row {
        display: flex;
        align-items: flex-end;
      }

      h3 {
        font-weight: normal;
        font-size: 28px;
        line-height: 38px;
        color: $gray-2;
        float: none;
      }

      .review-card-body-left {
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-avatar {
          position: relative;
          width: 132px;
          height: 132px;
        }
        span {
          font-weight: normal;
          font-size: 64px;
          color: $gray-4;
          display: block;
          height: 80px;
          float: none;
        }

        .rating {
          color: $gray-4;
        }
      }
      .review-card-body-right {
        flex: 1 1 auto;
        padding-top: 50px;
      }
    }
  }

  .icon-times {
    .icon {
      padding-top: 5px!important;
      width: 32px;
      height: 32px;
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 767px) {
    .review-card-maximized {
      .review-card-body {
        .review-card-row {
          display: block;
        }
        .review-card-body-right {
          padding-top: 0px;
        }
      }
    }
  }
}

@keyframes reviewCardShowEffect {
  0% {
    opacity: 0;
    max-height: 100%;
  }
  50% {
    opacity: 0;
    max-height: 250%;
  }
  100% {
    opacity: 1;
    max-height: 400%;
  }
}
