@import '../../../global/styles/variables.scss';
@import '../../../global/styles/_breakpoints.scss';

.black-november-banner {
  $self: &;
  display: flex;
  justify-content: space-between;
  font-family: 'Robot Crush', 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  width: 100%;
  padding: 0.875rem 1.25rem;
  background-image: url('/product/static/images/black-november/banner-top-black-november.png');
  background-repeat: no-repeat;
  background-color: #191c1f;
  background-size: cover;
  letter-spacing: 0.04em;
  color: #c5310a;
  position: relative;
  border-radius: 12px;

  &__tag,
  span {
    width: 90px !important;
    height: auto !important;
    min-height: auto !important;
  }

  @media (min-width: $mobile-l) {
    justify-content: space-around;
  }

  @media (max-width: $mobile-m) {
    &__tag,
    span {
      width: 70px !important;
    }
  }

  &__text {
    display: flex;
    align-items: baseline;
    font-size: 12px;
    padding: 0.25rem 0.5rem;
    background: #f5cf67;

    strong {
      color: $red;
    }

    &--highlight {
      font-size: 1.4rem;
      text-decoration: underline;
      margin: 0 0.5rem;
    }
  }

  &__mobile {
    & > div {
      margin-bottom: 0.25rem;
    }
  }

  &__cta {
    background-color: $red;
    color: $white;
    text-decoration: initial;
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }

  @media (min-width: $tablet) {
    height: 60px;

    &__tag,
    span {
      width: 220px !important;
      height: 30px !important;
    }

    &__text {
      font-size: 1rem;
    }
  }
}
