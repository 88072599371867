@import '../../global/styles/variables';

.alert-preview {
  font-family: $font;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #6C5200;
  background: #FFFAEB;
  border: 1px solid #F9E298;
  box-sizing: border-box;
  border-radius: 4px;
  position: fixed;
  z-index: 99999;
  width: 100%;
  padding: 16px;

  &__icon {
    width: 18px;
    fill: #6C5200;
  }

  &__info {
    padding-left: 16px;

    &-link {
      color: #5981e3;
      text-decoration: none;
    }
  }

  @media (max-width: $tablet) {
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: initial;
    min-height: 80px;
  }
}

.content-preview {
  margin-top: 0 !important;
  padding-top: 4rem !important;

  .checkout-wrapper {
    padding-top: 140px!important;

    &.checkout-alert {
      padding-top: 168px !important;
    }
  }

  @media (max-width: 1024px) {
    &.minimized {
      padding-top: 150px;
    }
  }
}

.header-preview {
  .hm-mktplace-header__container {
    top: 60px!important;

    @media (max-width: $desktop) {
      top: 80px!important;
    }
  }
}
