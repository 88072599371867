@import '../../global/styles/variables';
@mixin playVideo {
  content: '';
  border-style: solid;
  border-width: 0.5rem 0 0.5rem 0.75rem;
  border-color: transparent transparent transparent var(--gray-600);
  margin-left: 2px;
  border-radius: 0.175rem;
}

// VIDEO -------------------------------------------------------------------------------------
#video {
  @media (max-width: $mobile-l) {
    min-height: 202px;
  }

  @media (min-width: $tablet) {
    min-height: 450px;
  }

  .section_video {
    @media (min-width: $desktop-m) {
      min-height: 408px;
      padding: 0;
    }

    &.hide {
      display: none;
    }
  }

  @media (max-width: $tablet) {
    padding: 1.5rem 1rem 0 1rem;
  }
}

.videoWrapper {
  @media (min-width: $mobile) {
    min-height: 202px;
  }

  @media (min-width: $tablet) {
    min-height: 435px;
    position: relative;
    overflow: hidden;
  }
}

.videoWrapper__vimeo-video {
  position: relative;
  padding-bottom: 56.5%;
  display: block;
  overflow: hidden;
  margin-top: 1rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoWrapper__vimeo {
  position: relative;
  display: block;
  overflow: hidden;

  &-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 3.125rem;
    height: 3.125rem;
    background-color: var(--white);
    border-radius: 50%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    cursor: pointer;
    outline: none;
    border: none;
    z-index: 1;
    opacity: 1;

    @media (min-width: $tablet-min) {
      width: 3.5rem;
      height: 3.5rem;
    }

    &:after {
    @include playVideo;
    position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      -moz-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-thumb {
    border-radius: 6px;
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    
      background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, #000000 100%);
      mix-blend-mode: multiply;
      opacity: 0.4;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: calc(100% / (16 / 9));
    }
  }

  @media (min-width: $mobile) {
    min-height: 210px !important;
    border-radius: 0;
  }

  @media (min-width: $tablet) {
    border-radius: 4px;
    min-height: 300px !important;
  }

  @media (min-width: $desktop) {
    min-height: 400px !important;
  }
}

.yt-lite {
  border-radius: 6px;
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* Gradient */
.yt-lite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.51), rgba(0, 0, 0, 0.51));
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Youtube Play Button */
.yt-lite > .lty-playbtn {
  width: 3.125rem;
  height: 3.125rem;
  background-color: var(--white);
  z-index: 1;
  opacity: 1;
  border-radius: 50%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
  cursor: pointer;
  border: none;

  @media (min-width: $tablet-min) {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* play button triangle */
.yt-lite > .lty-playbtn:before {
  @include playVideo;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}
