.product-image-wrapper {
  position: relative;

  div:nth-of-type(1) {
    display: block !important;
  }

  .product-image {
    display: block;
    width: 100%;
    border-radius: 0.4rem;
  }

  .product-image-with-strip {
    border-radius: 5px 5px 0 0 !important;

    @media (max-width: 768px) {
      border-radius: 10px 10px 0 0 !important;
    }
  }
}

.redesigned-main-title-image {
  .product-image {
    border-radius: 0.75rem;
  }
}
