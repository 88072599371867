.sessionPackageInfo {
  width: 100%;
  height: auto;
  min-height: 104px;

  border-radius: 4px;
  background-color: #f0f3f7;

  display: flex;
  align-items: center;

  padding: 16px;
  margin-bottom: 16px;

  &--mobile {
    min-height: auto;

    padding: 4px 8px;
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    align-items: flex-start;

    color: #6b7480;
    font-size: 14px;

    &--mobile {
      font-size: 12px;
      align-items: center;
    }

    & > span {
      margin: 0.2rem 0.5rem 0 0!important;
    }

    &__icon {
      color: var(--brand-primary);

      margin: 8px 16px 0 0;

      &--mobile {
        margin: 0 10px 0 0;
      }
    }
  }
}
