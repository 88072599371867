$ease: cubic-bezier(0.4, 0, 0.14, 1);

$timing: 800ms;
$timing5: 500ms;
$timing3: 300ms;

// timing
$timing-8: 800ms;
$timing-5: 500ms;
$timing-3: 300ms;

// FONTS -----------------------------------------------------------------

$font: 'Nunito Sans', sans-serif;
$fontA: 'Font Awesome 5 Pro';

// GRID ------
$unidade: 90px;
$gutter: 16px;

$borderRadius: 6px;

@function tamanho($number) {
  $temp: $number - 1;
  $value: $unidade * $number + $gutter * $temp;
  @return $value;
}

// CUSTOM
$borderRadius: 4px;

// COLORS -----------------------------------------------------------------
$white: #ffffff;
$black: #353a40;
$red: #ef4e23;
$red-2: #f04e23;
$red-3: #ffb09c;
$red-4: #fc592f;
$red-6: #ed4f2e;
$hm: #053d4e;
$light: #51c1c3;
$gray-2: #484e56;
$gray-3: #f0f3f7;
$gray-4: #6b7480;
$gray-5: #cecece;
$gray-6: #dfe2e6;
$gray-7: #5f6772;
$gray-8: #fafbfc;
$gray-9: #d0d3d6;
$gray-medium: #9b9b9b;
$blue-1: #1c76d0;

$grey-7: #dfe2e6;
$grey-9: #6b7480;
$grey-13: #e8e8e8;
$grey-14: #343a42;
$grey-15: #5f6771;

$blue-1: #1174d3;

$bgContent: #fafbfc;
$text: #333;

$green: #009D43;

$black: #000;

$color-primary: #f04e23;
$color-tertiary: #51c1c3;

$text-color: $black;
$text-contrast: #212529;

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
