@import '../../../global/styles/variables.scss';
@import '../../../global/styles/breakpoints';

.product-card {
  $self: &;

  .product-image-wrapper {
    overflow: hidden;
    min-height: 229px;
    border-radius: .375rem;

    & > div {
      position: initial!important;
    }

    .product-image {
      background: #FFFFFF;
      transition: $timing-5 all $ease;
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      display: block;
    }

    @media (max-width: $mobile-l) {
      min-height: 192px;

      & > div {
        max-height: 192px;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__content {
    position: relative;
    padding: 8px;
    flex: 1 0 auto;
  }

  &__rating {
    display: flex;
    align-items: center;
    &__stars {
      flex: 0 0 auto;
      color: #ed4f2e;
      line-height: 10px;
      svg {
        width: 10px;
        margin-right: 1px;
        &.star-half{
          height: 9px;
        }
        &.star-regular{
          color: #DDDEE0;
        }
      }
    }
    &__total {
      margin-left: 4px;
      font-size: 8px;
      line-height: 10px;
      color: #5f6771;
    }
  }

  &__title {
    font-weight: bold;
    word-break: break-word;
    color: var(--gray-800);
    transition: all $ease $timing-5;
  }

  &__author {
    font-size: 12px;
    color: var(--gray-500);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    display: flex;
    padding: 0px 10px 16px 16px;
    align-items: flex-end;
    justify-content: space-between;
    color: $gray-4;
    font-weight: normal;
  }

  &__rating {
    display: flex;
    flex-wrap: nowrap;
    color: $grey-9;
    margin-bottom: 10px;
  }

  &:hover {
    background: $gray-3;

    .product-image-wrapper {
      .product-image {
        transform: scale(1.05);
      }
    }

    #{$self}__title {
      color: var(--blue);
    }
  }

  &--redesign {
    .product-image-wrapper {
      min-height: 184px;
    }
  }

  @media screen and (min-width: $tablet-mid-2) {
    &__content {
      padding: 20px 16px 32px;
    }

    &__title {
      font-size: 16px;
      max-height: 70px;
      overflow: hidden;
      line-height: normal;
    }

    &__author {
      font-size: 14px;
      line-height: 1.71;
    }

    &--redesign {
      .product-card__content {
        padding: 1rem 0 0;
      }
    }
  }
}
