@import '../../global/styles/variables';

.buy-cart__orange {
  color: #fff !important;
  background-color: #f04e23 !important;
  border-color: #f04e23 !important;
}

.link-button {
  text-decoration: none;

  .button-text {
    margin-right: 8px;
  }

  .button-icon {
    height: 20px;
  }

  &.checkout-closed {
    background-color: transparent;
    color: $gray-4;
    font-weight: normal;
    pointer-events: none;
    cursor: not-allowed;

    @include hover-supported() {
      background-color: transparent;
      color: $gray-4;
    }

    @media (min-width: $mobile) {
      font-size: 16px;
      word-break: break-word;
      max-width: 190px;
      padding: 0 !important;
    }

    @media (min-width: $desktop) {
      font-size: 22px;
      max-width: 100%;
      margin-bottom: 32px;
    }
  }
}
