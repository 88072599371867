#advantages {
  display: flex;
  flex-direction: column;

  .advantages-container {
    -webkit-column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-count: 2;
    -moz-column-gap: 40px;
    column-count: 2;
    column-gap: 40px;
  }

  p {
    font-size: 16px;
    line-height: 29px;
    color: $black;
    width: 100%;
  }

  @media (max-width: 1149px) {
    h3 {
      font-size: 18px;
    }
  }

  @media (max-width: 750px) {
    .advantages-container {
      -webkit-column-count: 0;
      -webkit-column-gap: auto;
      -moz-column-count: 0;
      -moz-column-gap: auto;
      column-gap: 0;
      column-count: auto;
    }
  }
}
