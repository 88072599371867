@import '../../global/styles/variables';

.reviews-wrapper {
  .reviews-header {
    display: flex;
    align-items: center;
    
    h2 {
      flex: 1;

      @media (min-width: $desktop-min) {
        line-height: 100%;
      }
    }

    .link-arrow {
      flex: 0 0 auto;
      white-space: nowrap;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      
      svg {
        color: $color-primary;
      }
    }
  }

  @media (min-width: $tablet) {
    .review-rows {
      min-height: 600px;
    }
  }

  .review-product {
    line-height: 150%;
    margin-top: 1rem;

    &__hotmarter {
      font-size: 0.8rem;
      line-height: 150%;
      color: var(--gray-400);
      text-transform: uppercase;
      margin-top: 0.5rem;
      padding-bottom: 1rem;
    }

    &__header {
      display: flex;
      align-items: center;

      @media (max-width: $mobile-max) {
        .tags__rating-global {
          padding-left: 0;
        }
      }
    }

    &__icon {
      padding-left: 0.4rem;

      & > div {
        overflow: initial!important;

        img {
          margin-top: 2px!important;
        }
      }
    }

    &__date {
      font-size: 12px;
      color: var(--gray-400);
      padding-left: 0.8rem;
    }

    &__read-more {
      margin-top: 1rem;
    }
  }

  .review-box {
    &:not(:nth-last-of-type(2)) {
      border-bottom: 1px solid var(--gray-200);
    }

    &:nth-last-of-type(1) {
      .review-product__hotmarter {
        margin-bottom: 1rem;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 2;
    .review-card {
      flex: 1;
      min-width: 350px;

      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }

  .review-card{
    &.open {
      flex-basis: 100%;
    }
  }

  .rating-box {
    margin-bottom: 1.7rem;
  }

  @media screen and (max-width: 767px){
    .row {
      display: block;
      flex-direction: column;
      margin-bottom: 0px;
      flex-wrap: wrap;
      .review-card{
        margin-bottom: 20px;
      }
    }
    .review-card{
      margin-bottom: 0px;
    }

    .reviews-header {
      align-items: start;

      h3 {
        margin: 0;
      }
    }
  }
}
