@import '../../global/styles/variables';

// TAG ----------------------------------------------------------------------------------------------
.tags {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.75rem;
  line-height: 180%;
  color: var(--black);

  &__label {
    color: var(--gray-500);
    margin-right: 0.25rem;
  }

  &__section {
    display: flex;
    align-items: baseline;
  }
}

.tag-category {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  border-radius: 14px;
  font-style: normal;
  padding: 0.5rem 1rem 0.5rem 0.2rem;

  &--title {
    flex-wrap: wrap;
    line-height: 1rem;
  }

  &--highlight {
    font-weight: bold;
    padding-left: 0.5rem;
  }

  &--blue {
    background: var(--blue-lightest);
    color: var(--blue-darkest);
    padding-left: 0.85rem;
    border-radius: 3rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  &--gray {
    color: #32363B;
    background: #F7F9FA;
    border: solid 1px #C9CED4;
    padding-left: 0.85rem;
    border-radius: 3rem;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
}

.tag-format {
  display: flex;
}

@media (min-width: $desktop-m) {
  .tags,
  .tag-category {
    font-size: 14px;
  }
}
