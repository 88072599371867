.tooltip-arrow {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.sessionPackageInfo__tooltip {
  position: relative;
}

.tooltip-container {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: all $ease $timing3;
  z-index: 2;

  &.show-tooltip {
    opacity: 1;
    pointer-events: auto;
  }
}

.tooltip {
  padding: 4px;

  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 24, 53, 0.2);
  background-color: #191c1f;

  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #fafbfc;

  &:before {
    @extend .tooltip-arrow;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    border-color: transparent #191c1f transparent transparent;
  }

  &[data-placement^="bottom"] {
    &:before {
      @extend .tooltip-arrow;
      bottom: 100%;
      left: 50%;
      border-color: transparent transparent #191c1f transparent;
    }
  }

  &[data-placement^="top"] {
    &:before {
      @extend .tooltip-arrow;
      top: 100%;
      left: 50%;
      border-color: #191c1f transparent transparent transparent;
    }
  }

  &[data-placement^="right"] {
    &:before {
      @extend .tooltip-arrow;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
      border-color: transparent #191c1f transparent transparent;
    }
  }

  &[data-placement^="left"] {
    &:before {
      @extend .tooltip-arrow;
      top: 50%;
      left: 100%;
      margin-right: -5px;
      margin-top: -5px;
      border-color: transparent transparent transparent #191c1f;
    }
  }
}
