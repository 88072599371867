@import "../../global/styles/variables";

.loading {
  &-cards{
    width: 100%;
    height: 40px;
    float: left;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items:center;
    color: $gray-medium;

    svg {
      color: $gray-medium;
      fill: $gray-medium;
      height: 30px;
      width: 100%;
    }
  }
  &-text {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-content: center;
    align-items:center;
    color: $gray-medium;

    svg {
      color: $gray-medium;
      fill: $gray-medium;
      height: 100%;
      width: 100%;
    }
  }

  &-tag {
    width: 100%;
    float: left;
    display: block;
    color: $gray-medium;
    float: left;
    clear: left;
    margin: 10px 0 -13px;

    svg {
      color: $gray-medium;
      fill: $gray-medium;
      height: 100%;
      width: 100%;
    }
  }

  &-text {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    color: $gray-medium;
    float: left;
    clear: left;
    margin: 10px 0;

    svg {
      color: $gray-medium;
      fill: $gray-medium;
      height: 100%;
      width: 100%;
    }
  }

  &-rating {
    width: 100%;
    float: left;
    display: block;
    color: $gray-medium;
    float: left;
    clear: left;
    margin: 10px 0 0 0;

    svg {
      color: $gray-medium;
      fill: $gray-medium;
      height: 100%;
      width: 100%;
    }
  }

}


