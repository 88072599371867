.redesigned-main-title {
  &-image {
    width: 5.625rem;
  }

  &-text {
    font-size: 0.938rem;
  }

  &-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-author {
    font-size: 0.875rem;
  }

  &-icon {
    :first-child {
      vertical-align: text-bottom;
    }
  }

  .rating-mobile {
    .tags__rating-global {
      margin-top: -0.25rem;
    }
  }
}

.rating-mobile {
  min-height: 30px;
}

.redesigned-main-container {
  .tags__rating-global span {
    font-size: 0.75rem;
  }
}

.redesigned-content {
  .main-title__component {
    min-height: 345px;
  }
}

@media (max-width: $tablet) {
  .redesigned-main-title-icon {
    .tags__rating-global {
      font-size: 0.75rem;
      padding-left: 8px;
    }
  }
}

@media (min-width: $tablet) {
  .redesigned-main-container {
    min-height: 184px;
  }

  .redesigned-main-title-tags {
    &,
    .tags__rating-global span {
      font-size: 0.875rem;
    }

    .rating--title {
      padding-right: 0.5rem;
    }

    .tags__rating-global {
      margin-right: 0;
    }

    .redesigned-main-title-icon {
      min-height: 1.938rem;
    }

    .redesigned-main-title-icon:not(:first-child) {
      padding-left: 2.5rem;
    }

    .redesigned-share-button {
      position: absolute;
      right: 0.75rem;
    }
  }

  .redesigned-content {
    .main-title__component {
      min-height: 225px;
    }
  }

  .redesigned-main-title {
    &-image {
      width: 11rem;
    }
  }
}
