.sessionPackageAlert {
  width: 100%;
  height: auto;
  min-height: 84px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  background-color: rgba(15, 133, 209, 0.08);

  margin-top: 24px;
  padding: 12px;

  &__content {
    display: flex;
    align-items: flex-start;

    color: #0f85d1;
    font-size: 12px !important;

    &__icon {
      color: #0f85d1;
      font-size: 14px !important;

      margin: 8px 16px 0 0;
    }

    & > span {
      margin: 0.25rem !important;
    }
  }
}
