.not-found {
  &__header {
   padding: 32px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (min-width: $desktop) {
      padding: 32px;
    }

    &-image {
      width: 104px;
      height: 33px;
    }
  }

  &__figure {
    margin-bottom: 32px;
    cursor: pointer;

    @media (min-width: $desktop) {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;

    @media (min-width: $desktop) {
      flex-direction: row;
    }
  }

  &__text {
    width: 100%;

    h1 {
      font-weight: normal;
      color: rgb(21,83,100);
      margin: 0 0 16px 0;
      font-size: 28px;

      @media (min-width: $desktop) {
        font-size: 36px;
      }
    }

    h3 {
      color: var(--gray-600);
      font-weight: normal;
      margin: 0 0 8px 0;
      @media (min-width: $desktop) {
        font-size: 16px;
      }
    }

    @media (min-width: $desktop) {
      min-width: 540px;
      margin-left: 40px;
      width: auto;
    }
  }
}
