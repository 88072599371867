@import '../../global/styles/variables';

// CREDIT CARDS ---------------------------------------------
.coCards {
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  // display: none;
  .center {
    overflow: unset;
  }

  .credcards {
    width: 40px !important;
    height: 30px;
    margin: 0 5px;
    background-color: $white;
    border-radius: $borderRadius;
    overflow: hidden;
    position: relative;
    outline: none;
    border: 1px solid $gray-3;
    cursor: grab;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      position: absolute;
    }
  }



  &.no-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-bottom: 8px;
    .credcards{
      flex: 0 0 auto;
      width: 45px;
      margin-bottom: 5px;
      cursor: initial;
      img{
        width: 100%;
        position: relative;
      }
    }
  }

  &.schedule-credit-cards {
    justify-content: flex-start;
    align-items: center;

    .credcards {
      width: 24px !important;
      height: 18px;
      margin: 0 3px;
      background-color: $white;
      border-radius: $borderRadius;
      overflow: hidden;
      position: relative;
      outline: none;
      border: 1px solid $gray-3;
      cursor: grab;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
