.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--gray-900);
  overflow-x: scroll;
  width: 100%;
  border-bottom: 1px solid var(--gray-200);

  &--redesigned {
    max-width: 85rem;
    border: none;
  }

  > a,
  button {
    text-decoration: none;

    :hover {
      text-decoration: underline;
      color: var(--gray-900);
      cursor: pointer;
    }
  }

  &__dropdown {
    padding-right: 2.75rem;
    width: 34px;

    a {
      color: #32363b;
    }

    .ellipsis-button {
      & > span {
        display: initial !important;
        overflow: initial !important;

        .dropdown-icon {
          position: initial !important;
          width: 4px !important;
          height: 12px !important;
          max-width: initial !important;
          margin-right: -0.125rem !important;
        }
      }

      span {
        width: 12px !important;
      }
    }

    .dropdown {
      position: absolute;
    }

    .dropdown-item {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #32363b;

      &--icon {
        margin-left: 0.5rem;
      }
    }
  }

  &__icon {
    margin-left: 1rem;
  }

  &__text {
    color: var(--gray-900);
  }

  > button {
    all: unset;
  }

  & svg {
    vertical-align: baseline !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__section {
    display: flex;
    line-height: 1rem;
    padding: 0.75rem 1.25rem;
    margin: 0.5rem 0;
    width: inherit;

    &.short {
      border-right: 1px solid var(--gray-200);
      width: 7.5rem;
    }

    &__categories {
      &.short {
        width: auto;
      }
    }

    &__categories {
      max-width: 57rem;
      padding-right: 0;
      width: max-content;
    }
  }

  &__home {
    width: max-content !important;
  }

  &__category-label {
    display: inline-block;
    margin-right: 1rem;

    &__ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10rem;

      @media (max-width: $mobile-l) {
        max-width: 12rem;
      }
    }
  }

  .dropdown-menu {
    width: 1.75rem;
    height: auto;
    margin-top: -1.5rem;

    &:hover {
      background: #f7f9fa !important;
      border-radius: 100px !important;
      width: 27px;
      border-color: transparent !important;
    }
  }
}
