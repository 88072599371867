
.tags {
  display: flex;
  flex-wrap: wrap;

  .tag-category {
    &:first-child {
      margin-left: 0;
    }
  }

  &__rating-global {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: 28px;
    margin-right: 1rem;
    font-size: 0.625rem;

    .rating{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-bottom: 2px;
    }
    
    &__total-reviews {
      display: inline-block;
      float: none;
      letter-spacing: 0.02em;
      margin: 0 0 0 0.25rem!important;
      color: var(--gray-500);
    }
  }

  @media (min-width: $mobile) {
    justify-content: flex-start;
  }

  @media (max-width: $mobile-max) {
    &__rating-global {
      padding-left: 16px;
    }
  }
}

.rating-total {
  font-weight: 700;
  margin: 5px 2px 5px 0!important;
}

@media (min-width: $desktop) {
  .tags {
    &__rating-global {
      justify-content: flex-start;
      font-size: 0.75rem;

      &__total-reviews {
        color: var(--blue);
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}