.ordering-filter {
  &__spacing {
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: flex-end;
    padding: 2.5rem 2rem 0 0;
    display: flex;
  }

  &__button {
    color: var(--blue);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    cursor: pointer;
    transition: all $ease $timing3;
    padding: 0;
    font-size: .75rem;
    font-weight: bold;
    line-height: 150%;
    color: var(--gray-800);
    background-color: transparent;
    border: none;

    & > span {
      width: 1rem!important;

      img {
        min-height: 1rem!important;
      }
    }

    &-text {
      font-size: 1rem;
      font-family: 'Nunito Sans';
      font-weight: 300;
      width: auto!important;
      white-space: nowrap;
      margin-right: .25rem;
    }
  }

  &__icon {
    width: 12px;
  }

  &__options {
    padding: 1rem;
    max-width: 220px;
    height: 184px;
    list-style: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: absolute;
    z-index: 2;
    background-color: $white;
    margin-top: 2.5rem;
    opacity: 0;
    pointer-events: none;
    transition: all $ease $timing3;

    &-item {
      font-size: 0.8rem;
    }

    &--horizontal {
      height: auto;

      .ordering-filter__options-item {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 70%;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-bottom-color: var(--white);
      border-top: 0;
      margin-left: -19px;
      margin-top: -19px;
    }

    &-active {
      opacity: 1;
      pointer-events: auto;
    }

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-item {
      width: 100%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: var(--blue);
        font-weight: bold;
      }
    }
  }

  &__box {
    &-shadow {
      &-active {
        width: 180px;
        height: 184px;
        background: #FFFFFF;
        position: absolute;
        margin-top: 39px;
        pointer-events: none;
        z-index: 1;
        box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2), 0px 6px 10px rgba(112, 119, 128, 0.14), 0px 1px 18px rgba(112, 119, 128, 0.12);
        border-radius: 4px 0px 4px 4px;

      }
    }
  }
}
