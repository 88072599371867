.redesigned-author {
  .icon-light {
    height: .9rem !important;
  }

  &__social {
    a {
      text-decoration: none;
      color: var(--gray-600);

      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    img {
      min-height: inherit !important;
      width: 1.25rem !important;
      height: 1.25rem !important;
      object-fit: contain;
    }

    @media(min-width: $tablet-mid) {
      margin-top: .625rem;
    }
  }
}
