@import 'src/global/styles/variables';

// Modules -------------------------------------------------------------------------------------

.summary {
  position: relative;
}
.accordion__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  background-color: $white;

  h3 {
    margin: 0;
    font-weight: 500;
  }

  &:before {
    position: absolute;
    right: 10px;
  }
}

.hot-nav--tabs {
  hot-nav-item {
    font-size: 1.125rem;
  }
}

.accordion__modules {
  width: 100%;

  .accordion__item {
    margin: 0 !important;
  }
}

.redesigned-content {
  .hot-nav__item--active, hot-nav-item[active] {
    color: black !important;
    box-shadow: inset 0px -4px 0 -1px var(--black) !important;

    :focus, :hover {
      color: black !important;
    }
  }
}

.modules {
  display: none;

  &__total {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }

  &__hours {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #707780;
  }

  &__info {
    margin-left: 0.75rem;
  }

  @media (min-width: $desktop) {
    display: initial;
    border: 1px solid #e6e9ed;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    margin-bottom: 2.5rem;
  }
}
