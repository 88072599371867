.review-card-sizing {
  min-width: 16.5rem;
  max-width: 16.5rem;
  height: 18.625rem;

  @media (max-width: $tablet) {
    margin-right: 1.5rem;
    overflow-x: auto;
  }

  .review-paragraph {
    height: 9.5rem;
    overflow-y: hidden;
  }
}
