@import '../../global/styles/variables';

// CONTENT -------------------------------------------------------------------------------------

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  opacity: 1;
  padding: 0;
}

.content-center {
  display: flex;
  max-width: 1256px;
  width: 100%;
  margin: 0 auto;
  transition: all $ease $timing5;
  padding-top: 110px;
  padding-bottom: 80px;

  &.redesigned-grid {
    max-width: 1256px;
    padding-top: 0;
  }

  @media (max-width: $mobile-l) {
    padding-bottom: 1rem;
  }

  .top-back-button {
    padding-right: 1.5rem;
    border-radius: 0.2rem;

    @media(max-width: 1300px) {
      margin: 0rem 0 0 1rem;
    }

    .icon {
      width: 48px;
      border-radius: 4px;
      margin-left: -1rem;

      svg {
        font-size: 14px;
        width: 13px;
      }
    }

    .link-arrow-text {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 150%;
      color: var(--gray-600);
      margin-left: -8px;
    }

    &:hover {
      &,
      .icon {
        background: #e8e9eb !important;
      }

      .link-arrow-text {
        color: $grey-14;
      }
    }
  }

  &--marketplace {
    padding-top: 96px;
  }

  #title {
    .title {
      display: flex;
      flex-direction: row;
    }

    .rating-share {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    @media (max-width: $mobile-max) {
      padding: 0;

      .section__name {
        padding: 16px 8px 0 16px;
      }

      .title {
        .main-title {
          font-size: 22px !important;
          line-height: 26px;
        }

        &-header {
          padding-left: 16px;
          padding-bottom: 16px;
        }
      }
    }

    .avatar-title {
      flex: 0 0 61px;

      div {
        padding-top: inherit !important;

        .image-avatar {
          width: 48px !important;
          height: 48px !important;
          min-height: inherit !important;
          min-width: inherit !important;
          border-radius: 50%;
          margin: 0 !important;
        }
      }

      &__image {
        @media (max-width: $mobile-max) {
          min-width: 61px;
          min-height: 48px;
        }
      }
    }

    .avatar-title > div {
      position: relative !important;
      margin-bottom: 8px !important;
    }

    h3 {
      margin-bottom: 8px;
      line-height: 38px;
    }
  }

  .advantages-ia {
    margin-top: 1.5rem;
    border: none;

    & > div {
      display: flex;
      align-items: baseline;
    }

    &-text {
      padding-left: 1rem;
      flex: 1;
    }
  }

  #tabs {
    @media (min-width: $mobile) {
      min-height: 0;
    }

    @media (min-width: $desktop) {
      min-height: 200px;
    }
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 1024px) {
    padding-top: 3.5rem;
  }
}

.column-content {
  padding: 0;
  max-width: tamanho(8);
  display: block;
  width: 100%;
  flex-direction: column;

  .section-divider {
    padding: 2.1875rem 1rem;

    @media(max-width: 540px) {
      &#content {
        .text-wrapper {
          min-height: 130px;

          .product-image-wrapper {
            @media (min-width: $mobile-max) {
              margin-right: 15px;
            }
          }
        }
      }

      &#faq {
        @media (max-width: $mobile-l) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    @media(min-width: 1280px) {
      padding: 2.1875rem 2rem 2.1875rem 0;
    }
  }

  .section__title {
    display: flex;
  }

  .section-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .back-button {
      height: 23px;
      border-right: 1px solid var(--gray-200);
    }
  }

  .section-description {
    .section-divider {
      padding-top: 1.5rem;
    }
  }

  .main-title__component {
    &.product-info {
      margin-bottom: 3rem;
    }

   .product-info {
      background: var(--gray-100);

      .product-image-wrapper {
        width: 88px!important;

        & > div {
          height: 88px!important;
        }
      }

      .title {
        align-items: flex-end;
      }

      .tags-list {
        margin-top: 1.2rem;

        .tag-category {
          font-size: 9px;
          height: 24px;
          font-style: normal;
          font-weight: normal;
        }
      }

      .tags__rating-global {
        padding-left: 0;
      }

      &__author {
        color: var(--gray-800);
        margin-left: .5rem;
      }

      &__product {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
      }

      &__name {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        margin-bottom: .5rem;
      }
    }
  }


  .text-description {
    height: 100%;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    max-height: 315px;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;

    &--paragraphs {
      -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
      mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    }

    @media (max-width: $mobile-max) {
      max-height: 19.375rem;

      p {
        display: inline-block;
      }
    }
  }

  .section__title-header {
    flex: 2;
  }

  @media (min-width: $desktop) {
    .section__title-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 0.2rem;
    }

    .text-description {
      &--large {
        -webkit-line-clamp: 12;
        -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
        mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
      }

      &--paragraphs {
        max-height: 322px;
      }
    }
  }

  @media (max-width: 720px) {
    display: flex;
    padding-top: 0;
  }
}

.column-checkout {
  width: tamanho(4);
  margin-left: 60px;

  @media (max-width: 1279px) {
    position: sticky;
    bottom: 0;
    z-index: 9999;
  }

  @media (max-width: 1281px) {
    max-width: tamanho(7);
  }
}

#details {
  padding-bottom: 0px;
  margin-bottom: 30px;

  .section_detail {
    display: flex;
    flex-wrap: wrap;
    .section_detail_item {
      margin-top: 30px;
      flex: 1 1 50%;

      button {
        border: none;
        cursor: pointer;
        background-color: transparent;
        text-align: left;
        padding: 0;
      }
    }
    span {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      h3 {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $gray-4;
        text-transform: uppercase;
      }
      p {
        margin-top: 5px;
        color: $black;
        font-size: 16px;
        line-height: 26px;
        transition-delay: 0ms;
        font-weight: 500;
      }
    }

    .section_detail_language {
      border-radius: 3px;
      border: solid 1px $blue-1;
      background-color: #e7f2fd;
      padding: 4px 16px;
      color: $blue-1;
      width: fit-content;

      @media (max-width: $mobile-content-max) {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 770px) {
    .section_detail {
      flex-direction: row;
      span {
        align-items: center;
        flex: 0 0 100%;
      }
    }
  }

  @media (max-width: 1599px) {
    .section_detail {
      span {
        align-items: initial;
      }
    }
  }

  @media (max-width: 400px) {
    width: 280px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.column_productImages {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: right;

  .photos_container {
    justify-content: flex-end;
  }
}

.main-title {
  font-size: 2rem;
  color: var(--gray-800);
  line-height: 120%;
  font-weight: 300;
  margin: 0 0 1.25rem 0;
}

.section {
  position: relative;
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}

@media (max-width: 992px) {
  .content {
    left: 0 !important;
  }

  .title {
    align-items: center;
  }
}

@media (min-width: $desktop) {
  .column-content {
    .main-title__component {
      padding-bottom: 0.95rem;

      .tags-list {
        padding-left: 16px;
      }
    }
  }

  .section-navigation {
    margin: 0 !important;
  }
}
