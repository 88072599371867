.rating-grid {
  .rating {
    font-size: 8px;
    color: $gray-4;
    margin: 0px;
  }

  .star-detail {
    display: flex;
    align-items: center;
    .qty-stars {
      flex: 0 0 auto;
      line-height: 15px;
      margin-right: 34px;
      .rating--title {
        margin: 0;
      }
    }
    .qty-stars-bar {
      flex: 1 1 auto;
    }

    .percentage-bar {
      width: 100%;
      height: 6px;
      background: #dfe2e6;
      border: 0;
      border-radius: 40px;
      overflow: hidden;

      @media(min-width: $desktop) { 
        width: 200px;
      }

      .percentage-bar-progress {
        background: #6b7480;
        display: block;
        height: 100%;
        border-radius: 40px;
        -webkit-transition: all .3s cubic-bezier(.4,0,.14,1);
        -o-transition: all .3s cubic-bezier(.4,0,.14,1);
        -moz-transition: all .3s cubic-bezier(.4,0,.14,1);
        transition: all .3s cubic-bezier(.4,0,.14,1);
      }
    }
  }
}
