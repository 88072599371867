.product-image-modal {
  &__container {
    width: 33.5rem;
    height: 33.5rem;
  }

  &__share-spacing {
    margin-top: -2.125rem;
  }
}
