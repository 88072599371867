@import '../../global/styles/variables';

// RATING ----------------------------------------------------------------------------------------------

.rating {
  font-family: $fontA;
  font-weight: 400;
  width: auto;
  font-size: 12px;
  text-align: left;
  position: relative;
  transition: all $ease $timing3;
  display: inline-block;

  &:after {
    font-weight: 900;
    content: '';
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
    font-size: inherit;
    text-align: left;
  }

  .star {
    width: 15px;
    fill: #f04e23;
  }
  
  .star-light {
    width: 14px;
  }

  span {
    float: none;
    position: relative;
    display: inline;
    padding-bottom: 1px;
    
    .star-left {
      position: absolute;
      left: 51%;
      transform: translate(-50%, -55%);
      top: 28%;
    }

    .star-right {
      transform: rotateY(180deg);
      fill: $gray-6;
      padding-bottom: 0.5px;
    }
  }

  .star-solid-disabled {
    width: 14px;
    fill: $gray-6;
  }
}

.stars-details {
  .star {
    width: 9px;
    fill: var(--gray-700)
  }

  .star-solid-disabled {
    fill: $gray-6;
  }
}
