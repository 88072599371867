@import '../../global/styles/variables';

$green-2: #7cdbdb;
$base-spacing: 16px;

.hm-mktplace-footer {
  padding-bottom: 110px;
  background-color: $white !important;
  z-index: inherit;

  * {
    box-sizing: border-box;
  }

  [hidden] {
    display: block !important;
  }

  .wrap {
    max-width: 1256px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}

#hm-mktplace-footer {
  position: relative;
  background-color: $white;
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;

  .social {
    .menu-social {
      .link-info {
        visibility: hidden;
      }
    }

    .opened {
      .menu-social {
        .link-info {
          visibility: visible;
        }
      }
    }
  }

  .hm-mktplace-footer {
    max-width: 1256px;
    width: 100%;
    margin: 0 auto;

    .block--bottom {
      padding: 48px 0 73px 0;
      height: 120px;
    }
  }

  @media (min-width: $desktop) {
    z-index: 9999;
  }
}
