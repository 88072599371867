#outros-produtos {
  .related-products {
    margin-bottom: 2rem;

    @media (max-width: $mobile-l) {
      .glider-track {
        width: fit-content!important;
      }
    }
  }

  @media (max-width: $desktop-min) {
    padding-right: 0;

    .glider-contain {
      width: auto;
      margin: 0;
      margin-left: -1rem;

      .glider-slide {
        &:first-of-type {
          margin-left: 1rem;
        }
      }
    }
  }
}
