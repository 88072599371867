@import '../../global/styles/variables';

.price {
  text-align: left;

  &__previous{
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;

    &__label {
      color: var(--gray-500);
      position: relative;
      font-size: 12px;
      text-decoration: line-through;

      &:before{
        content: '';
        border-bottom: 2px solid var(--hotmart-primary-dark);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &__discount{
      position: relative;
      background: var(--hotmart-primary-dark);
      border-radius: 50px;
      padding: 0px 8px;
      svg{
        position: relative;
        z-index: 1;
      }
      &__label{
        z-index: 2;
        font-weight: bold;
        display: inline-block;
        padding-top: 2px;
        background: var(--purple);
        border-radius: 3px;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--white);
        padding: 5px;
      }
    }
  }

  &--value {
    font-size: 2rem;
    line-height: 40px;
    font-weight: 400;
    line-height: 120%;

    &--abtest {
      .price--value--amount {
        font-size: 20px;
        line-height: 24px;
        color: var(--gray-600);
        font-weight: normal;
      }
    }

    &--currency {
      font-weight: 200;
      letter-spacing: -2.3px;
      color: $gray-2;
      display: inline-block;
      margin-right: 5px;
    }
  }

  &--vat {
    font-size: 12px;
    color: $gray-4;

    @media (min-width: $mobile) {
      margin-bottom: 0;
    }

    @media (min-width: $desktop) {
      margin-bottom: 16px;
    }
  }

  &--description {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.9px;
    color: $gray-2;
  }

  &-schedule {
    margin-bottom: 0;

    .price--value {
      font-size: 20px;
      line-height: 16px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 8px;
    }
  }
}

.checkOut.side-checkout .price-schedule {
  margin-bottom: 0;
}

.mobile-price {
  position: relative;
  color: var(--gray-800);

  @media (min-width: $desktop-min) {
    display: none;
  }

  &__discount-label{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: .75rem;
    line-height: 150%;
    border-radius: 1rem;
    padding: .125rem  .53125rem;
    color: var(--white);
    background-color: var(--brand-primary);
  }

  .price {
    &__previous {
      margin-bottom: 0;

      &__label {
        font-size: .75rem;
        line-height: 150%;
        color: var(--gray-800) !important;
      }

      &__discount {
        &__label {
          margin-bottom: 0;
          margin-left: .5rem;
          font-size: .75rem !important;
          line-height: 150%;
          border-radius: 1rem;
          padding: .125rem  .53125rem !important;
          color: var(--white);
          background-color: var(--brand-primary);
        }
      }
    }

    &--value {
      font-size: 1.75rem;
      line-height: 120%;

      &--amount {
        font-size: 1.75rem !important;
        line-height: 120% !important;
      }
    }
  }

  .copy-text {
    font-size: .75rem;
    line-height: 150%;
  }

  .tags__rating-global {
    padding-left: 0;
    min-height: 0;
  }
}
