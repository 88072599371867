@import '../../global/styles/variables';

#programacao {
  display: flex;
  flex-direction: column;
  //margin-bottom: 48px;

  h3 {
    font-size: 24px;
    font-weight: normal;
    color: $black;
    letter-spacing: 0 !important;
    line-height: 28px;
    text-align: left;
    float: left;
    width: 100%;
    margin: 0px;
    margin-top: 60px;
    margin-bottom: 48px;
  }

  .schedule-container {
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #dfe2e6;
    padding: 16px;
    height: 100%;
    width: 100%;
    color: $text-color;

    /* Old Chrome, Safari and Opera */
    -webkit-column-count: 2;
    -webkit-column-gap: 40px;

    /* Old Firefox */
    -moz-column-count: 2;
    -moz-column-gap: 40px;

    /* >Standard syntax */
    column-count: 2;
    column-gap: 40px;
  }

  p {
    font-size: 16px;
    line-height: 29px;
    color: $text-color;
    width: 100%
  }

  @media (max-width: 1149px) {
    h3 {
      font-size: 18px;
    }
  }

  @media (max-width: 750px) {
    .schedule-container {
      -webkit-column-count: 0;
      -webkit-column-gap: auto;
      -moz-column-count: 0;
      -moz-column-gap: auto;
      column-gap: 0;
      column-count: auto;
    }
  }

}
