.rating-main-box {
  display: flex;

  .rating-main-box-grade {
    font-size: 96px;
    line-height: 92px;
    color: var(--gray-600);
    font-weight: normal;
    margin-right: 1rem;
  }

  .rating-main-box-ratings-qty {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-right: 40px;

    .star {
      width: 15px;
    }

    .star-left {
      top: 54%;
    }

    .star-right {
      padding-bottom: 0.2px;
    }

    .rating {
      display: block;
      color: $color-primary;
      font-size: 24px;
      margin: 0px;

      & > div, 
      & > span {
        width: 54px!important;
        height: 39px!important;

        .image-icon {
          min-width: 27px !important;
          width: 27px!important;
          margin: 0 !important;
          margin-left: .5rem !important;
        }
      }

      @media (max-width: 540px) {
        display: flex;
        align-items: center;
        color: $color-primary;
        font-size: 16px;
        margin: 0px;
        flex: 1 1 auto;
      }
    }

    .rating-main-box-many-reviews {
      font-size: 16px;
      color: #343A42;
      line-height: 26px;
      margin-left: .5rem;
    }


    @media(max-width: 540px) {
      align-items: flex-start;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .rating-main-box-grade {
      font-size: 48px;
      line-height: 60px;
    }
  }
}
