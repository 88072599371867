@import '../../../global/styles/variables.scss';
@import '../../../global/styles/_breakpoints.scss';

.product-card-mini {
  $self: &;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  transition: all $ease $timing-5;
  text-align: left;

  &__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
  }

  &__rating {
    display: flex;
    align-items: center;
    margin-top: 10px;
    &__stars {
      flex: 0 0 auto;
      color: #ed4f2e;
      line-height: 10px;
      svg {
        width: 10px;
        margin-right: 1px;
        &.star-half{
          height: 9px;
        }
        &.star-regular{
          color: #DDDEE0;
        }
      }
    }
    &__total {
      margin-left: 4px;
      font-size: 8px;
      line-height: 10px;
      color: #5f6771;
    }
  }

  .product-image-wrapper {
    width: 45%;

    .product-image {
      border-radius: 6px;
      border: solid 1px #f0f3f7;
      object-fit: cover;
      width: 100%;
      height: auto;
      object-position: 50% 50%;
      display: block;
      min-height: 142px;

      @media (max-width: $mobile-l) {
        width: 142px;
      }
    }
  }

  &__content-wrapper {
    max-width: 50%;
    width: 50%;
    padding: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile) {
      padding-left: 16px;
    }
  }

  &__content {
    position: relative;
    flex: 1 1 100%;
  }

  &__title {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    word-break: break-word;
    color: $gray-2;
    transition: all $ease $timing-5;
    max-height: 63px;
    overflow: hidden;
    margin-top: 8px;
  }

  &__author {
    font-size: 12px;
    line-height: 1.71;
    color: #6d7278;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer {
    display: flex;
    margin-top: 8px;
    align-items: flex-end;
    justify-content: space-between;
    color: $gray-4;
    font-weight: normal;
    margin-bottom: 10px;
  }

  &__rating {
    display: inline-flex;
    flex-wrap: nowrap;
    color: $grey-9;
  }

  &__category {
    border-radius: 12px;
    background-color: var(--gray-200);
    margin-left: -4px;
    padding: 3px 8px;
    font-size: 14px;
    color: $gray-2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all $ease $timing-5;
  }

  @media (hover: hover) {
    &:hover {
      background: $gray-3;
      #{$self}__title {
        color: $red-6;
      }

      #{$self}__category{
        background-color: $white;
      }
    }
  }
}
