@import "../../global/styles/variables";

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0.9);
  z-index: 99999;
  transition: opacity 450ms;
  opacity: 1;
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-revert {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.content-loader {
  &--container {
    display: block;
    overflow: hidden;
    position: relative;
  }

  &--loader {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &--shine {
    animation: shine 2500ms infinite linear;
    background-color: var(--gray-200);
    background-image:
      linear-gradient(
        90deg,
        var(--gray-200) 0,
        var(--gray-100) 45%,
        var(--gray-200) 90%
      );
    background-size: 200%;
    border-radius: var(--border-radius);
    display: block;
    height: 100%;
    width: 100%;
  }
}

@keyframes shine {
  0% {
    background-position: 100%;
  }

  40% {
    background-position: -100%;
  }

  100% {
    background-position: -300%;
  }
}
