@import '../../global/styles/variables';

.link-arrow {
  color: inherit;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  .link-arrow-text {
    position: relative;
    z-index: 2;
    transition: all $ease $timing5;
    color: #353A40;
  }

  .arrow-icon {
    min-width: 12px!important;
  }

  .arrow-left {
    transform: rotate(180deg);
  }

  .icon {
    padding: 0 0.8rem;
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 22px;
    text-align: center;
    margin-left: -5px;
    z-index: 0;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.icon-left {
      transform: rotate(180deg);
    }
  }


  &:hover {
    .icon {
      background: #e6fff0 !important;
    }
  }
}
