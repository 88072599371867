.signup-content {
  background-color: transparent !important;
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 5;

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      font-size: 0.825rem;
      list-style: none;

      span {
        font-size: 0.875rem;
        line-height: 1.2;

        &:first-child {
          align-items: flex-start;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 100px;
          display: flex;
          font-size: 1.1rem;
          font-weight: 800;
          justify-content: center;
          margin-right: 0.5rem;
          max-height: 2rem;
          min-width: 2rem;
          padding: 0.25rem;
        }
      }
    }
  }

  &__image {
    height: auto;
    object-fit: cover;
    max-width: 100%;
  }

  // abstract image
  &--abstract {
    background-color: #d93e14;
    box-shadow: 0px 20px 56px -32px rgba(0, 0, 0, 0.75);
    margin: 3.5rem;
    padding: 2.5rem;

    h3 {
      margin-bottom: 2rem;
      text-align: center;
    }

    ul {
      gap: 1.75rem;

      li {
        gap: 0;
      }
    }
  }

  // photo image
  &--photo {
    bottom: 0;
    margin: 0 5.5rem 3rem 5.5rem;

    h3 {
      margin-bottom: 1rem;
      text-align: left;
    }

    ul {
      gap: 0.25rem;

      li {
        background-color: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(12px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 0.5rem;
      }
    }
  }
}
