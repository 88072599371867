@import 'global/styles/variables';

// CONTENT -------------------------------------------------------------------------------------

.redesigned-content {
  .reviews {
    &-header {
      h2 {
        color: var(--neutral-gray-800);
        font-size: 1.75rem;
        font-weight: 800;
      }

      .ordering-filter {
        padding-top: 3rem;
      }
    }

    &-wrapper {
      .rating-main-box-grade {
        color: var(--neutral-gray-900);
        font-size: 6rem;
        font-weight: 700;

        @media screen and (max-width: $mobile-l) {
          font-size: 2.5rem;
        }
      }
      .tags__rating-global {
        .rating-total {
          font-size: var(--text-2);
        }
      }

      .review-product__date {
        padding-left: 0px;
        font-size: 0.75rem;
        font-weight: 700;
      }

      .review-product__hotmarter {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }

  .review-product__read-more {
    color: var(--color-neutral-900) !important;
    font-size: 1rem;
    font-weight: 800;
    line-height: 150%;
    text-decoration-line: underline;
    border: none;
    background-color: transparent !important;
    padding-left: 0px;
    gap: 0.5rem;

    &:hover,
    &:focus,
    &:active {
      color: var(--black) !important;
      border: none !important;
      background-color: transparent !important;
    }
  }

  .content-center {
    display: flex;
    max-width: 1256px;
    width: 100%;
    margin: 0 auto;
    transition: all $ease $timing5;
    padding-top: 110px;
    padding-bottom: 80px;

    &.redesigned-grid {
      padding-top: 0;
    }

    &--marketplace {
      padding-top: 70px;
    }

    @media (max-width: $mobile-l) {
      padding-bottom: 1rem;
    }

    #title {
      @media (max-width: $mobile-max) {
        padding: 0 1rem;
      }
    }
  }

  .checkOut.side-checkout .checkout-container {
    top: 0px;
  }

  .checkOut.side-checkout .checkout-container--sticky {
    top: 4.5rem;
  }

  #video {
    padding: 0;

    .yt-lite,
    .videoWrapper__vimeo-thumb {
      border-radius: 0.75rem;
    }
  }

  .redesigned-modules {
    border-bottom: 0.0625rem solid #e6e9ed !important;

    hot-collapse {
      .accordion__item {
        padding: 0.5rem 0;
        margin: 0.75rem 0;
      }

      .accordion__section {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 120%;
        color: var(--gray-900);
        padding: 0.6rem;
        background-color: var(--gray-100);
        border-radius: 50%;
      }

      .accordion__text {
        display: block;
      }
    }

    ul {
      padding: 0 1rem !important;
      font-size: 1.125rem;
    }

    .accordion {
      &__title {
        font-weight: 800;
        font-size: 1.125rem;
        line-height: 120%;
        color: var(--gray-900);
      }
    }
  }

  .review-container {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .review-modal {
    z-index: 999999;
  }

  .product-data {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 0.875rem;
  }

  .faq-container {
    .accordion__item {
      padding: 0 !important;
    }

    .accordion__description {
      padding: 0.75rem 0.5rem 2rem 0;
    }
  }

  .sales-argument {
    &--5,
    &--4 {
      min-height: 140px !important;
    }

    &--6 {
      min-height: 155px !important;
    }

    &__icon {
      width: 1.25rem;
    }
  }

  .checkout-container__sales {
    .image-icon {
      object-fit: contain;
    }
  }

  .sidebar__access {
    padding: 1rem 0 !important;
    justify-content: center;
    border-bottom: 0.0625rem solid #e6e9ed !important;

    &-redesign {
      transform: rotate(180deg);
    }
  }

  .checkout-container--courses {
    border: 1px solid #e6e9ed;
    border-radius: 16px;
    padding: 32px 24px 20px !important;
    left: 25px !important;
    box-shadow: none !important;

    &--closed {
      border: none;
      padding-right: 0 !important;
    }

    &.checkout-container--sticky {
      max-width: 297px !important;
      border-radius: 1rem !important;
    }

    .checkout-container__buy {
      min-height: auto !important;
      justify-content: center !important;
    }
  }

  .checkout-container--bn.checkout-container--courses {
    padding-top: 4.5rem !important;
  }

  .price--value {
    font-weight: 800;
  }

  .related-products-container {
    padding-right: 0 !important;
  }

  .disclaimer-container {
    padding-left: 1rem;
  }

  .share-button {
    min-height: 40px;
  }

  .column-content {
    padding: 0;
    display: block;
    flex-direction: column;

    .section-divider {
      padding: 2.5rem 1rem 0rem;
    }

    .product-description--hide {
      max-height: 320px;
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgb(255, 255, 255) 90%
        );
        width: 100%;
        height: 3em;
      }
    }

    @media (max-width: 720px) {
      display: flex;
      padding-top: 0;
    }
  }

  .column-checkout {
    width: tamanho(4);
    margin-left: 5rem;

    @media (max-width: 1279px) {
      position: sticky;
      bottom: 0;
    }
  }

  .main-title {
    font-size: 2rem;
    color: var(--gray-800);
    line-height: 120%;
    font-weight: 300;
    margin: 0 0 1.25rem 0;
  }

  .section {
    position: relative;
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    color: #191c1f;
  }

  @media (max-width: 992px) {
    .content {
      left: 0 !important;
    }

    .title {
      align-items: center;
    }
  }

  @media (min-width: $tablet) {
    .column-content {
      min-width: 832px;
      width: 832px;

      .related-products {
        padding-bottom: 1rem;
        padding-left: 0;

        .glider-contain {
          .glider-next,
          .glider-prev {
            display: none;
            height: 30px;
            width: 30px;
            background: rgba(0, 0, 0, 0.81) !important;
            border: none !important;
            color: var(--white);
            top: 30% !important;

            &.disabled {
              opacity: 0;
            }

            .image-icon {
              object-fit: contain;
              min-width: 80% !important;
            }
          }

          .glider-next {
            right: -0.75rem !important;
          }

          .glider-prev {
            left: auto !important;
          }

          &:hover {
            .glider-next,
            .glider-prev {
              display: flex;
            }

            .right-2 {
              box-shadow: inset -5rem -3rem 2rem 3rem rgba(255, 255, 255, 0.64);
            }

            .left-1:not(:first-child):not(:nth-child(4)) {
              box-shadow: inset 2rem -3rem 2rem 0rem rgba(255, 255, 255, 0.64);
            }

            .right-2 {
              .product-card {
                z-index: -1;
              }
            }
          }
        }
      }

      .section-divider {
        padding: 2.5rem 0;
      }

      .disclaimer-container {
        padding-left: 0;
      }

      .product-description--hide {
        max-height: 250px;
        overflow: hidden;
      }

      .product-data {
        grid-template-columns: 1fr 1fr !important;

        .image-icon {
          object-fit: contain;
        }
      }
    }

    .sidebar__access {
      border-bottom: none !important;
    }

    #video,
    .section_video,
    .videoWrapper {
      min-height: 385px !important;
    }
  }

  @media (min-width: $desktop) {
    .column-content {
      .main-title__component {
        padding-bottom: 0;

        .tags-list {
          padding-left: 16px;
        }
      }
    }
  }
}

.topification {
  &__list {
    padding: 0px;
    list-style: none;

    &--container {
      display: grid;
      grid-template-columns: 1fr;

      @media screen and (min-width: $desktop) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: var(--spacer-10);
      }
    }

    &-item > a {
      text-decoration: none;

      &:hover {
        color: var(--brand-primary) !important;
      }
    }
  }
}

.hm-mktplace-header__container {
  box-shadow: none !important;
  border-bottom: var(--component-border-width) solid
    var(--component-border-color) !important;
}
