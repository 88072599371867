@import "../../global/styles/variables";

// PHOTOS ---------------------------------------------
.photos_container {
  display: flex;
  flex-direction: row;
  margin: 40px 0 24px 0;

  .carousel {
    &__content {
      width: calc(280px * 2 + 32px);
      height: 160px;
      margin: 0 0 0 auto;

      @media (max-width: 520px) {
        width: 100%;
      }
    }

    &__item {
      overflow: hidden;

      img {
        border-radius: 6px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        display: block;
      }
    }
  }

  .photo {
    margin: 0;
    flex: 1 1 auto;
    border-radius: $borderRadius;
    overflow: hidden;

    &:nth-child(1) {
      margin-right: $gutter;
    }

    &:only-child {
      margin: 0px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: 50% 50%;
      display: block;
    }
  }

  @media (min-width: 771px) {
    .photo {
      max-width: 49%;
    }
  }

  @media (max-width: 570px) {
    flex-direction: column;

    .photo {
      &:nth-child(1) {
        margin: 0px;
        margin-bottom: $gutter;
      }

      &:only-child {
        margin: 0px;
      }
    }
  }
}

