.eventSchedule {
  &__container {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    color: $text-contrast;
    flex-direction: column;
    margin-left: 2px;

    &.checkout {
      margin-top: 16px;
      margin-left: 48px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .eventSchedule__date {
        margin-bottom: 12px;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (min-width: $tablet) {
    &__container {
      align-items: center;
      flex-direction: row;
      margin: 15px 0 8px 0;
    }
  }

  @media (min-width: 640px) {
    &__container {
      margin-top: 5px;

      &.checkout {
        margin-bottom: 33px;
        .eventSchedule__hour {
          min-width: 192px;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}


