@import 'global/styles/variables';

.faq-modal {
  &__body {
    overflow: auto;

    &:after {
      content: "";
      position: fixed;
      z-index: 4;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
      width: 100%;
      height: 3em;
    }

    @media screen and (max-width: $mobile-l) {
      max-height: 720px;
    }

    @media screen and (max-width: $mobile-m) {
      max-height: 550px;
    }
  }

  &__container {
    z-index: 99999;
  }
}
